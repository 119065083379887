import React, { useEffect, useState } from "react";
import { Header, ChatSidebar, SettingSidebar } from "./components";
import { getProjectDetailRequest } from "../../redux/slicers/project";
import { CustomDispatch } from "../../helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ERROR_ROUTE, PROJECT_SIDEBAR_PLACEMENT_RIGHT } from "../../constants";
import { Backdrop, Loader } from "../../components";
import clsx from "clsx";
import "./styles.scss";

const ProjectSharedLayout = ({ children }) => {
  // STATES
  const [chatPreview, setChatPreview] = useState(true);
  const [settingPreview, setSettingPreview] = useState(true);

  // CONST VALS
  const navigate = useNavigate();
  const { projectslug } = useParams();
  const { pathname } = useLocation();
  const childRoute = pathname.split("/")[3];

  // CUSTOM DISPATCH
  const [getProjectDetail, isLoading] = CustomDispatch(
    getProjectDetailRequest,
    true
  );

  // HELPERS
  const getProjectDetailHelper = () => {
    const payload = {
      resource: "project",
      method: "describe",
      details: {
        project_id: projectslug,
      },
    };
    getProjectDetail({
      payload,
      error() {
        navigate(ERROR_ROUTE);
      },
    });
  };

  // HANDLERS
  const chatPreviewHandler = () => {
    setChatPreview(!chatPreview);
  };

  const settingPreviewHandler = () => {
    setSettingPreview(!settingPreview);
  };

  const closeSidebar = () => {
    setChatPreview(true);
    setSettingPreview(true);
  };

  // HOOKS
  useEffect(() => {
    getProjectDetailHelper();
  }, [projectslug]);

  // show loader while fetching project details
  if (isLoading) return <Loader height="100vh" />;

  return (
    <div className="project-wrapper">
      <Header
        childRoute={childRoute}
        settingPreviewHandler={settingPreviewHandler}
        chatsPreviewHandlder={chatPreviewHandler}
      />
      <main
        role="main"
        className={clsx(
          "layout-wrapper",
          PROJECT_SIDEBAR_PLACEMENT_RIGHT && "rtl",
          childRoute && "inner-page",
          !chatPreview && "chat-sidebar-active",
          !settingPreview && "setting-sidebar-active"
        )}
      >
        {!childRoute && (
          <>
            {(!chatPreview || !settingPreview) && (
              <Backdrop isMobile onClick={closeSidebar} />
            )}
            <ChatSidebar
              preview={chatPreview}
              previewHandler={chatPreviewHandler}
            />
            <SettingSidebar
              preview={settingPreview}
              previewHandler={settingPreviewHandler}
            />
          </>
        )}
        {children}
      </main>
    </div>
  );
};

export default ProjectSharedLayout;
