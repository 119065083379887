import React, { useCallback, useState } from "react";
import { Form } from "antd";
import { Button } from "../../../../../components";
// import { CHAT_OPTIONS } from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  // faMicrophone,
  // faPaperclip,
  faSquare,
  // faStop,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// import { imageValidation } from "../../../../../utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useSelector } from "react-redux";
import "./styles.scss";

const InputBox = React.forwardRef(
  (
    {
      selectedProject,
      sendMessage,
      stopResponse,
      isLoading,
      chatOption,
      setChatOption,
      selectedImages = [],
      removeSelectedImage,
      selectImage,
    },
    ref
  ) => {
    // STATES
    // const [chatOptionPreview, setChatOptionPreview] = useState(false);
    const [message, setMessage] = useState("");

    // REDUX DATA
    const fieldsView = useSelector(({ project }) => project.fieldsVisibility);
    const fieldsValues = useSelector((state) => state.project.fieldsValues);

    // CONST VALS
    const [form] = Form.useForm();
    // const chatOptions = CHAT_OPTIONS.filter((x) => x.value !== chatOption.value);
    const {
      // transcript,
      listening,
      resetTranscript,
      // browserSupportsSpeechRecognition,
      // browserSupportsContinuousListening,
    } = useSpeechRecognition();
    const inputPlaceholder = listening
      ? "Listening..."
      : fieldsView.input
      ? fieldsValues.input
      : "";

    // HANDLERS
    // const recordingHandler = () => {
    //   if (listening) {
    //     SpeechRecognition.stopListening();
    //     resetTranscript();
    //     return;
    //   }
    //   setMessage("");
    //   SpeechRecognition.startListening({
    //     continuous: browserSupportsContinuousListening,
    //   });
    // };
    // const uploadImageHandler = (e) => {
    //   const files = e.target.files;
    //   let uploadedfiles = [];
    //   if (!files) return;
    //   Array.from(files).forEach((item) => {
    //     if (imageValidation(item)) uploadedfiles.push(item);
    //   });
    //   selectImage(uploadedfiles);
    // };
    // const setSelectedChatOptionHandler = (item) => {
    //   setChatOption(item);
    //   setChatOptionPreview(false);
    // };
    // const setChatOptionPreviewHandler = () => {
    //   setChatOptionPreview(!chatOptionPreview);
    // };
    const setMessageHandler = (e) => {
      setMessage(e.target.value);
    };

    const generateMessageHandler = () => {
      if (isLoading) return stopResponse();
      if (message.trim() === "") return;
      setMessage("");
      sendMessage(message);
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        generateMessageHandler();
      }
    };

    // HOOKS
    const inputRef = useCallback(
      (node) => {
        if (!node) return;
        // set textarea height
        node.style.height = "20px";
        const nodeHeight = node.scrollHeight;
        node.style.height = nodeHeight + "px";

        // set chat wrapper height
        if (!ref || !ref.current) return;
        const contentSpacing = window.innerWidth > 768 ? 95 : 65;
        const screenHeight = window.innerWidth > 768 ? 130 : 115;
        const defaultHeight =
          selectedImages.length > 0
            ? screenHeight + contentSpacing
            : screenHeight;

        const textareaHeight =
          nodeHeight > 20 ? nodeHeight - 40 : nodeHeight - 20;
        const calcheight = textareaHeight + defaultHeight;
        ref.current.style.height = `calc(100% - ${calcheight}px)`;
      },
      [message, selectedImages]
    );

    // useEffect(() => {
    //   console.log("transcript", transcript);
    //   if (transcript !== "") {
    //     setMessage(transcript);
    //   }
    // }, [transcript]);

    // useEffect(() => {
    //   // function to close chat option preview on outside click
    //   document.addEventListener("click", (e) => {
    //     if (
    //       !e.target.closest(".chat-selector") &&
    //       !e.target.closest(".chat-options")
    //     ) {
    //       setChatOptionPreview(false);
    //     }
    //   });
    // }, []);

    return (
      <div className="inputbox-wrapper">
        <div className="input-box">
          <Form className="input-form" form={form}>
            {/* <label htmlFor="image-uploader" className="upload-button">
              image uplaod
              <input
                type="file"
                multiple
                id="image-uploader"
                onChange={uploadImageHandler}
              />
              <FontAwesomeIcon className="upload-icon" icon={faPaperclip} />
            </label> */}
            {/* PROMPT GENERATOR */}
            <textarea
              id="input-box"
              className="ant-input"
              ref={inputRef}
              placeholder={inputPlaceholder}
              value={message}
              onChange={setMessageHandler}
              onKeyDown={handleKeyPress}
            />
            {/* <Tooltip
              title={
                !browserSupportsSpeechRecognition
                  ? "Browser doesn't support speech recognition"
                  : listening
                  ? "Stop microphone"
                  : "Use microphone"
              }
            >
              <button
                className="upload-button"
                onClick={recordingHandler}
                disabled={!browserSupportsSpeechRecognition}
              >
                <FontAwesomeIcon
                  className="upload-icon"
                  icon={listening ? faStop : faMicrophone}
                />
              </button>
            </Tooltip> */}
            {/* PROMPT OPTION */}
            {/* <div
          className={`chat-selector ml-5 ${chatOptionPreview ? "active" : ""}`}>
          <button
            type="button"
            className="selected-chat"
            onClick={setChatOptionPreviewHandler}>
            <span>{chatOption.label}</span>
            <FontAwesomeIcon className="select-toggle" icon={faChevronDown} />
          </button>
          <ul className="chat-options">
            {chatOptions.map((item, index) => (
              <li key={index}>
                <button
                  type="button"
                  onClick={() => setSelectedChatOptionHandler(item)}>
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div> */}
            {/* GENERATE BUTTON */}
            <Button
              type="submit"
              className="send-btn"
              invertedTheme
              boldText
              onClick={generateMessageHandler}
              disabled={message.trim() === "" && !isLoading}
            >
              {/* <span>{generateBtnText}</span> */}
              <FontAwesomeIcon icon={isLoading ? faSquare : faArrowUp} />
            </Button>
          </Form>
          <div className="images-box">
            <div className="selected-images">
              {selectedImages.map((item, index) => (
                <div className="image-box" key={index}>
                  <div className="image-wrapper">
                    <img src={item} alt="" />
                  </div>
                  <span
                    role="button"
                    tabIndex={index}
                    className="delete-btn"
                    onKeyDown={() => removeSelectedImage(index)}
                    onClick={() => removeSelectedImage(index)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* PROMPT TAGLINE */}
        <div
          className="tagline"
          style={{ opacity: fieldsView.desclaimer ? 1 : 0 }}
        >
          {fieldsValues.desclaimer}
          {/* <span className="ml-5">Report an issue.</span> */}
        </div>
      </div>
    );
  }
);

export default InputBox;
