import React from "react";

const MarketPlace = () => {
  return (
    // <div className="learning">
      <div className="coming-soon">
        <h1>Coming Soon</h1>
      </div>
    // </div>
  );
};

export default MarketPlace;
