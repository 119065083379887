import React, { useEffect } from "react";
import { LabelBox } from "..";
import { CustomDispatch } from "../../../../../../helpers";
import { getProjectMediaStatsRequest } from "../../../../../../redux/slicers/project";
import { useSelector } from "react-redux";
import { Button } from "../../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Switch } from "antd";

const KnowledgeBase = ({
  switchFields,
  switchFieldsHandler,
  knowledgeBaseHandler,
}) => {
  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const stats = useSelector(({ project }) => project.projectMediaStats);

  // CUSTOM DISPATCH
  const [getProjectMediaStats] = CustomDispatch(getProjectMediaStatsRequest);

  // HELPERS
  const getProjectMediaStatsHelper = () => {
    const payload = {
      resource: "data",
      method: "list",
      details: {
        project_id: data.id,
        db_type: data.database,
      },
    };
    getProjectMediaStats({ payload });
  };

  // HOOKS
  useEffect(() => {
    getProjectMediaStatsHelper();
  }, []);

  useEffect(() => {
    if (!stats?.uploadingFiles && !stats?.uploadingUrls) return;
    const interval = setInterval(() => {
      getProjectMediaStatsHelper();
    }, 5000);
    return () => clearInterval(interval);
  }, [stats]);

  return (
    <>
      <LabelBox heading title="Knowledge base" />
      <p className="sub-description">
        Your knowledge base is a library of files and ASU URLs that your AI
        project can use to provide more accurate and helpful answers. Upload and
        manage them here.
      </p>
      <p className="uploaded-files">
        ({stats?.uploadedFiles ?? 0}) file
        {stats?.uploadedFiles > 1 ? "s" : ""} searchable, (
        {stats?.uploadedUrls ?? 0}) URL
        {stats?.uploadedUrls > 1 ? "'s" : ""} searchable
      </p>
      {stats?.uploadingFiles || stats?.uploadingUrls ? (
        <div className="processing-box">
          <FontAwesomeIcon icon={faSpinner} color="#f0ad4e" />
          {(stats?.uploadingFiles ?? 0) + (stats?.uploadingUrls ?? 0)}{" "}
          files/urls processing
        </div>
      ) : null}
      <div className="label-box">
        <LabelBox
          name="enableMedia"
          title="Enable assets search"
          message="Enabling this will restrict the model to only answering questions from your files."
        />
        <Switch
          id="enableMedia"
          checked={switchFields.enableSearch}
          onChange={() => switchFieldsHandler("enableSearch")}
        />
      </div>
      <Button
        onClick={knowledgeBaseHandler}
        className="manage-btn"
        type="button"
        boldText
        invertedTheme
      >
        Manage knowledge base
      </Button>
    </>
  );
};

export default KnowledgeBase;
