import React, { useRef } from "react";
import ContentEditable from "./editable-content";

const Tag = ({
  value,
  index,
  editable,
  inputRef,
  validator,
  update,
  readOnly,
  removeOnBackspace,
  remove,
}) => {

  // CONST VALS
  const innerEditableRef = useRef(null);
  const tagRemoveClass = `tag-input__tag__remove ${
    readOnly ? "tag-input__tag__remove-readonly" : ""
  }`;

  // HANDLERS
  const handleRemove = () => remove(index);

  return (
    <div className="tag-input__tag">
      {!editable && <div className="tag-input__tag__content">{value}</div>}
      {editable && (
        <ContentEditable
          value={value}
          inputRef={inputRef}
          innerEditableRef={innerEditableRef}
          className="tag-input__tag__content"
          change={(newValue) => update(index, newValue)}
          remove={handleRemove}
          validator={validator}
          removeOnBackspace={removeOnBackspace}
        />
      )}
      <button className={tagRemoveClass} onClick={handleRemove} />
    </div>
  );
};

export default Tag;
