import React, { useState, useMemo, useRef, useEffect } from "react";
import { InputBox, MessageBox } from "..";
import { useDispatch, useSelector } from "react-redux";
import { WebSocketHandlers } from "../../../../../services/web-sockets";
import { Loader } from "../../../../../components";
import {
  createChatRequest,
  getMessagesRequest,
  recieveMessageRequest,
  sendMessageRequest,
  clearMessages,
} from "../../../../../redux/slicers/chat";
import {
  CHAT_OPTIONS,
  MESSAGE_TIMEOUT,
  MESSAGE_TIMEOUT_ERROR,
  MESSAGE_TYPE_JSON,
} from "../../../../../constants";
// import { navigateToLogin } from "../../../../../utils";
import { Helmet } from "react-helmet";
import { Images } from "../../../../../theme";
import SplashScreen from "../splash-screen";
import { CustomDispatch } from "../../../../../helpers";
import { useCallback } from "react";
import { useLayoutEffect } from "react";
import clsx from "clsx";
import "./styles.scss";
import { useSearchParams } from "react-router-dom";

const ChatBox = () => {
  // STATES
  const [reversedScrolling, setReversedScrolling] = useState(false);
  const [initLoad, setinitLoad] = useState(true);
  const [scrollToKey, setScrollToKey] = useState();
  const [moreMessagessData, setmoreMessagesData] = useState(null);
  const [selectedChatOption, setselectedChatOption] = useState(CHAT_OPTIONS[0]);
  const [isLoading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [lastMessageGeneratedTime, setLastMessageGeneratedTime] = useState(null);

  // CUSTOM DISPATCH
  const [createChat, chatLoader] = CustomDispatch(createChatRequest);
  const [getMessages, messgesLoader] = CustomDispatch(getMessagesRequest);

  // REDUX DATA
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const { messages } = useSelector((state) => state.chat);

  // CONST VALS
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const messageBox = useRef(null);
  const messageHistory = useRef(null);
  const toastTimerRef = useRef(null);
  const messageWrapper = useRef(null);
  const itemRefs = useRef({});
  const selectedChat = searchParams.get("chat");
  const { sendJsonMessage, lastMessage, getWebSocket } = WebSocketHandlers();

  // HELPERS
  const stopResponseHelper = (data, closeConnection = true) => {
    setLoading(false);
    if (closeConnection) getWebSocket().close();
    if (messageHistory.current) messageHistory.current["isLoading"] = false;
    dispatch(recieveMessageRequest(data));
    messageHistory.current = null;
  };
  const generateMessagePayloadHelper = ({
    message = "",
    isError = false,
    isLoading = false,
  }) => {
    const payload = {
      isCompleted: false,
      isLoading: isError ? false : isLoading,
      isPrompt: true,
      model: selectedProject.modelLabel,
      message,
    };
    if (isError) payload["isError"] = true;
    return payload;
  };
  const sendMessageHelper = (message, session) => {
    startToastTimer();

    // create message payload
    const messagePayload = {
      action: "queryV2",
      session_id: session ?? selectedChat,
      project_id: selectedProject.id,
      query: message,
    };

    if (MESSAGE_TYPE_JSON) {
      messagePayload["response_format"] = { type: "json" };
      messagePayload.search_params = {
        ...messagePayload.search_params,
        output_fields: ["source_name", "page_number", "tags", "url"],
      };
    }

    // send message to server
    sendJsonMessage(messagePayload);
  };
  const createChatHelper = (message) => {
    const name = message.split("\n")[0];
    const chatName = `${name.slice(0, 30)}${name.length > 30 ? "..." : ""}`;
    const payload = {
      method: "create",
      details: {
        app_id: selectedProject.id,
        session_name: chatName,
      },
    };
    createChat({
      payload,
      success: (res) => {
        sendMessageHelper(message, res?.id);
        setSearchParams({ chat: res?.id });
      },
    });
  };
  const getMessagesHelper = () => {
    const payload = {
      method: "describe",
      details: {
        session_id: selectedChat,
        app_id: selectedProject.id,
      },
      item_limit: 30,
    };
    if (moreMessagessData) payload["next_page"] = moreMessagessData;
    getMessages({
      payload,
      otherkeys: generateMessagePayloadHelper({}),
      success: (data) => {
        const isReversed = data.messages?.messages?.length >= 8;
        setReversedScrolling(isReversed);
        setScrollToKey(messages?.[0]?.id);
        setmoreMessagesData(data?.nextPageData);
        if (!isReversed) scrollToBottom();
      },
    });
  };

  // HANDLERS
  const scrollToBottom = () => {
    if (messageBox.current) {
      const now = Date.now();
      if (
        lastMessageGeneratedTime &&
        now - lastMessageGeneratedTime > 1000
      ) {
        return; // Prevent scrolling if more than 1 second has passed
      }
      messageBox.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const scrolledToTop = Math.abs(scrollTop) + clientHeight === scrollHeight;
      setinitLoad(false);
      if (!scrolledToTop) return;
      if (!moreMessagessData || messgesLoader) return;
      getMessagesHelper();
    },
    [getMessagesHelper, messgesLoader]
  );
  const setSelectedChatOptionHandler = (item) => {
    setselectedChatOption(item);
  };
  const selectImageHandler = (images) => {
    const promises = [];
    images.forEach((image) => {
      const reader = new FileReader();
      promises.push(
        new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsDataURL(image);
        })
      );
    });
    return Promise.all(promises).then((dataURLs) => {
      setSelectedImages([...dataURLs, ...selectedImages]);
    });
  };
  const removeSelectedImageHandler = (index) => {
    const images = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(images);
  };
  const startToastTimer = () => {
    clearToastTimer();
    toastTimerRef.current = setTimeout(() => {
      setShowToast(true);
    }, MESSAGE_TIMEOUT * 1000);
  };
  const clearToastTimer = () => {
    if (toastTimerRef.current) {
      clearTimeout(toastTimerRef.current);
      toastTimerRef.current = null;
    }
  };
  const sendMessageHandler = (message) => {
    setLoading(true);
    // save message to reducer
    dispatch(sendMessageRequest(message));

    // save message to history
    const payload = generateMessagePayloadHelper({ isLoading: true });
    messageHistory.current = payload;

    // scroll to bottom
    setLastMessageGeneratedTime(Date.now());
    scrollToBottom();

    if (!selectedChat) {
      createChatHelper(message);
      return;
    }
    sendMessageHelper(message);
  };
  const stopResponseHandler = () => {
    stopResponseHelper(messageHistory.current);
  };

  // MANIPULATORS
  const manipulateJsonMessage = (lastMessage) => {
    const data = JSON.parse(lastMessage);
    const message = data?.response;
    if ("message" in data) return;

    // need to check if error is in data
    if ("error" in data) {
      stopResponseHelper(
        generateMessagePayloadHelper({ message: data.error, isError: true })
      );
      return;
    }
    if (message.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current["isCompleted"] = true;
      messageHistory.current["sources"] = data?.metadata?.sources ?? [];
      messageHistory.current["id"] = data?.metadata?.query_id;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if ("message" in data) return;
    const updatedmessage = messageHistory.current.message.concat(message);
    messageHistory.current = generateMessagePayloadHelper({
      message: updatedmessage,
      isLoading: false,
    });
  };
  const manipulateMessage = (lastMessage) => {
    if (lastMessage.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current["isCompleted"] = true;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if (lastMessage.includes(`message":`)) return;
    const message = messageHistory.current.message.concat(lastMessage);
    messageHistory.current = generateMessagePayloadHelper({
      message,
      isLoading: false,
    });
  };

  // HOOKS
  useLayoutEffect(() => {
    if (!scrollToKey || messageWrapper.current.scrollTop > 0 || initLoad)
      return;
    itemRefs.current?.[scrollToKey]?.scrollIntoView();
  }, [scrollToKey]);
  useEffect(() => {
    if (!showToast) return;
    stopResponseHelper(
      generateMessagePayloadHelper({
        message: MESSAGE_TIMEOUT_ERROR,
        isError: true,
      })
    );
    setShowToast(false);
  }, [showToast]);

  useMemo(() => {
    if (!selectedChat) {
      dispatch(clearMessages());
      getWebSocket()?.close();
      return;
    }
    if (!lastMessage) return;
    clearToastTimer();
    // if (lastMessage.includes(`message":`)) {
    //   // navigateToLogin()
    //   return;
    // }
    setLastMessageGeneratedTime(Date.now());
    // manipulate message based on type
    if (MESSAGE_TYPE_JSON) return manipulateJsonMessage(lastMessage.data);

    manipulateMessage(lastMessage.data);
  }, [lastMessage]);

  useEffect(() => {
    setinitLoad(true);
    setmoreMessagesData(null);
    if (isLoading) return;
    if (selectedChat) {
      getMessagesHelper();
      return;
    }
    dispatch(clearMessages());
  }, [selectedChat]);

  // CUSTOM COMPONENT
  const PromptThumb = () => (
    <img className="logo" src={Images.LogoThumb} alt="" />
  );

  return (
    <div className={clsx("chat-box", selectedImages.length >= 1 && "selected")}>
      {selectedProject?.webInterface && (
        <Helmet>
          <title>{selectedProject?.webTitle}</title>
        </Helmet>
      )}
      <div
        className={clsx("messages-wrapper", reversedScrolling && "reversed")}
        ref={messageWrapper}
        onScroll={handleScroll}
      >
        {messgesLoader && !moreMessagessData ? (
          <Loader height="100%" />
        ) : (
          <>
            {reversedScrolling && (
              <div className="scroller-box" ref={messageBox} />
            )}
            {messages.length >= 1 ? (
              <div className="messages-list">
                {moreMessagessData && messgesLoader && (
                  <Loader
                    height="30px"
                    size={10}
                    style={{ position: "absolute", top: 10, width: "100%" }}
                  />
                )}
                {messages.map((message, index) => (
                  <MessageBox
                    selectedProject={selectedProject}
                    key={index}
                    data={message}
                    ref={(ref) => (itemRefs.current[message.id] = ref)}
                    isLoading={isLoading || chatLoader}
                    previousMessage={messages[index - 1]}
                    reGenerateMessage={sendMessageHandler}
                    promptThumb={<PromptThumb />}
                  />
                ))}
                {messageHistory.current && (
                  <MessageBox
                    data={messageHistory.current}
                    selectedProject={selectedProject}
                    scrollToBottom={scrollToBottom}
                    promptThumb={<PromptThumb />}
                  />
                )}
              </div>
            ) : (
              <SplashScreen sendMessage={sendMessageHandler} />
            )}
            {!reversedScrolling && (
              <div className="scroller-box" ref={messageBox} />
            )}
          </>
        )}
      </div>
      <div className="bottom-wrapper">
        <InputBox
          ref={messageWrapper}
          selectedProject={selectedProject}
          selectedImages={selectedImages}
          selectImage={selectImageHandler}
          removeSelectedImage={removeSelectedImageHandler}
          sendMessage={sendMessageHandler}
          stopResponse={stopResponseHandler}
          isLoading={isLoading || chatLoader}
          chatOption={selectedChatOption}
          setChatOption={setSelectedChatOptionHandler}
        />
      </div>
    </div>
  );
};

export default ChatBox;
