import React, { useState } from "react";
import { Tabs } from "antd";
import clsx from "clsx";
import { BasicTab, AdvancedTab } from "./components";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KnowledgeBase from "../knowledge-base";
import "./styles.scss";

const SettingSidebar = ({ preview, previewHandler }) => {
  // STATES
  const [knowledgeBasePreview, setKnowledgeBasePreview] = useState(false);
  const [selectedTab, setselectedTab] = useState(1);

  // HANDLERS
  const tabChangeHandler = (key) => setselectedTab(key);
  const knowledgeBasePreviewHandler = () =>
    setKnowledgeBasePreview(!knowledgeBasePreview);

  // CONST VALS
  const tabs = [
    {
      key: 1,
      label: "Basic",
      children: <BasicTab knowledgeBaseHandler={knowledgeBasePreviewHandler} />,
    },
    {
      key: 2,
      label: "Advanced",
      children: <AdvancedTab changeTab={() => tabChangeHandler(1)} />,
    },
  ];

  return (
    <nav
      role="navigation"
      className={clsx("setting-sidebar", preview && "active")}
    >
      <button className="sidebar-toggle" onClick={previewHandler}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <Tabs
        onTabClick={tabChangeHandler}
        activeKey={selectedTab}
        className="project-setting-tabs"
        items={tabs}
      />
      <KnowledgeBase
        preview={knowledgeBasePreview}
        previewHandler={knowledgeBasePreviewHandler}
      />
    </nav>
  );
};

export default SettingSidebar;
