import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components";
import { DASHBOARD_ROUTE } from "../constants";
import { DashboardSharedLayout, PrivateSharedLayout, ProjectSharedLayout } from "../shared-layout";
import { getUserAuthentication, navigateToLogin } from "../utils";

const PublicRoute = ({ children }) => {
  return children;
};

const PrivateRoute = ({ children }) => {
  // CONST VALS
  const isAuthenticated = getUserAuthentication();

  // HOOKS
  useEffect(() => {
    if (!isAuthenticated) {
      navigateToLogin();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return <Loader />;
  return <PrivateSharedLayout>{children}</PrivateSharedLayout>;
};

const DashboardRoute = ({ children }) => {
  return (
    <PrivateRoute>
      <DashboardSharedLayout> {children}</DashboardSharedLayout>
    </PrivateRoute>
  );
};
const ProjectRoute = ({ children }) => {
  return (
    <PrivateRoute>
      <ProjectSharedLayout> {children}</ProjectSharedLayout>
    </PrivateRoute>
  );
};

const AuthRoute = ({ children }) => {
  // CONST VALS
  const navigate = useNavigate();
  const isAuthenticated = getUserAuthentication();

  // HOOKS
  useEffect(() => {
    if (isAuthenticated) {
      navigate(DASHBOARD_ROUTE);
    }
  }, [isAuthenticated, navigate]);

  return children;
};

export { PublicRoute, PrivateRoute, DashboardRoute, ProjectRoute, AuthRoute };
