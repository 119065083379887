// import { sortBy } from "lodash";
import { generateGuid } from "../utils";
import { MODEL_OPTIONS, ROLE_OPTIONS } from "../constants";

// GROUP DETAIL PROJECTS DATA
export const manipulateGroupDetailProjectsData = (data = []) => {
  let projects = [];
  data.forEach((item) => {
    const project = {
      id: item.project_id ?? generateGuid(),
      name: item.project_name ?? "",
      tpm: item.tpm,
      model: item?.model_name ?? "",
      modelLabel: item?.model_name
        ? MODEL_OPTIONS.find((x) => x.value === item?.model_name)?.label
        : "",
    };
    projects.push(project);
  });
  return projects;
};

// GROUP DETAIL USERS DATA
export const manipulateGroupDetailUsersData = (
  data = [],
  groupid = generateGuid()
) => {
  let users = [];
  data.forEach((item) => {
    const user = {
      id: item.user_id ?? generateGuid(),
      name: item.name ?? item.user_id,
      email: item.email ?? "",
      phone: item.phone ?? "",
      group: groupid,
      role: item.role,
      roleLabel: ROLE_OPTIONS.find((x) => x.value === item.role)?.label,
    };
    users.push(user);
  });
  return users;
};

// GROUP DETAIL DATA
export const manipulateGroupDetailData = (data) => {
  const group = {
    id: data.group_id ?? generateGuid(),
    name: data.group_name ?? "",
    description: data.description ?? "",
    tpm: data.tpm ?? 1,
    users: manipulateGroupDetailUsersData(data.users, data.group_id),
    projects: manipulateGroupDetailProjectsData(data.projects),
  };
  return group;
};
// GROUPS LISTING DATA
export const manipulateGroupsListData = (data) => {
  // const isDefault = data.group_name === "General";
  const group = {
    id: data.group_id ?? generateGuid(),
    // name: isDefault ? "None" : data.group_name ?? "",
    name: data.group_name ?? "",
    description: data.description ?? "",
    isDefault: false,
    tpm: data.tpm ?? 1,
    users: manipulateGroupDetailUsersData(data.users, data.group_id),
    projects: manipulateGroupDetailProjectsData(data.projects),
  };
  return group;
};

// GROUPS LISTING
export const manipulateGroupsList = (data) => {
  let groups = [];
  data.forEach((item) => {
    groups.push(manipulateGroupsListData(item));
  });
  // groups = sortBy(groups, "isDefault").reverse();
  return groups;
};
