import React from "react";
import { Button, Modal } from "../../../../../../components";
import { CustomDispatch } from "../../../../../../helpers";
import { deleteProjectRequest } from "../../../../../../redux/slicers/project";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { inputFieldRule, toastAlert } from "../../../../../../utils";
import { DASHBOARD_ROUTE, TOAST_MESSAGES } from "../../../../../../constants";
import { Form, Input } from "antd";

const DeleteProject = ({ preview, closeHandler }) => {
  // CONST VALS
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);

  // CUSTOM DISPATCH
  const [deleteProject, deleteLoader] = CustomDispatch(deleteProjectRequest);

  // HANDLERS

  const submitHandler = () => {
    const payload = {
      resource: "project",
      method: "delete",
      details: {
        project_id: data.id,
      },
    };
    deleteProject({
      payload,
      success: () => {
        toastAlert(TOAST_MESSAGES.PROJECT_DELETE_SUCCESS);
        closeHandler();
        navigate(DASHBOARD_ROUTE);
      },
    });
  };

  return (
    <Modal
      open={preview}
      width={640}
      openHandler={closeHandler}
      className="delete-project-modal"
    >
      <h2 className="title">Confirm project deletion</h2>
      <p className="description">
        Are you sure you want to delete{" "}
        <span className="bold">{data?.projectName}</span> . This will
        permanently delete your project and can not be undone.
      </p>
      <Form form={form} className="customize-form" onFinish={submitHandler}>
        <label htmlFor="confirm-field">Please type DELETE to confirm</label>
        <Form.Item
          name="delete"
          rules={[
            ...inputFieldRule({ name: "Confirmation" }),
            {
              validator: (_, value) => {
                if (!value) return Promise.reject();
                if (value !== "DELETE") {
                  return Promise.reject("Please type DELETE to confirm");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input id="confirm-field" type="text" placeholder="DELETE" />
        </Form.Item>
        <div className="action-box">
          <Button
            className="back-btn"
            boldText
            type="button"
            onClick={closeHandler}
          >
            Back
          </Button>
          <Button isLoading={deleteLoader} boldText invertedTheme type="submit">
            Delete Project
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DeleteProject;
