import React, { useEffect, useState } from "react";
import { Button, Modal } from "../../../../../../components";
import { Form, Input, InputNumber, Switch } from "antd";
import { inputFieldRule, isValidASUURL } from "../../../../../../utils";

const AddUrlForm = ({
  preview,
  previewHandler,
  submit,
  isLoading,
  isEdit,
  data,
}) => {
  // STATES
  const [depth, setDepth] = useState(1);
  const [docIndexing, setdocIndexing] = useState(false);

  // CONST VALS
  const [form] = Form.useForm();

  // HELPER
  const addPayloadHelper = (url) => {
    const data = {
      name: url,
      docIndexing: docIndexing,
      indexDepth: depth,
      exposed: false,
      referenced: true,
      tags: [],
    };
    return data;
  };

  const editPayloadHelper = (url) => {
    const payload = {
      ...data,
      isUpdated: true,
      name: url,
      docIndexing: docIndexing,
      indexDepth: depth,
    };
    return payload;
  };

  const submitHandler = ({ url }) => {
    const trimmedurl = url.endsWith("/") ? url.slice(0, -1) : url;
    const payload = isEdit
      ? editPayloadHelper(trimmedurl)
      : addPayloadHelper(trimmedurl);
    submit(payload);
  };

  // HOOKS
  useEffect(() => {
    form.resetFields();
    setdocIndexing(false);
    setDepth(1);
    if (isEdit && data) {
      form.setFieldsValue({
        url: data.name,
      });
      setdocIndexing(data.docIndexing);
      setDepth(data.indexDepth);
    }
  }, [isEdit, data, preview]);

  return (
    <Modal
      open={preview}
      width={570}
      openHandler={previewHandler}
      className="add-url-form"
    >
      <h2 className="title">{isEdit ? "Edit" : "Add"} URL</h2>
      <p className="description">
        {isEdit
          ? "Please edit the URL you would like to reference in your GPT."
          : "Please add any ASU websites you would like to reference in your GPT."}
      </p>
      <Form form={form} className="customize-form" onFinish={submitHandler}>
        <label htmlFor="url-field">Website URL</label>
        <Form.Item
          name="url"
          rules={[
            ...inputFieldRule({ name: "Url" }),
            {
              validator: (_, value) => {
                if (!value) return Promise.reject();
                const trimmedUrl = value.endsWith("/")
                  ? value.slice(0, -1)
                  : value;
                const isValidUrl = isValidASUURL(trimmedUrl);
                if (!isValidUrl) {
                  return Promise.reject("Please enter a valid ASU URL");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            disabled={isEdit}
            id="url-field"
            type="text"
            placeholder="https://"
          />
        </Form.Item>
        <label htmlFor="index-field">Indexing depth level</label>
        <p className="sub-description">
          Enter the number of levels to scrape beyond the main URL (maximum: 4).
          A higher value retrieves more content from deeper pages within the
          website.
        </p>
        <div className="indexing-field">
          <InputNumber
            min={0}
            max={4}
            id="index-field"
            maxLength={1}
            value={depth}
            onChange={(val) => setDepth(val)}
          />
          <p className="helper-text">0 - 4 (default is 1)</p>
        </div>
        <div className="document-indexing-box">
          <label htmlFor="document-indexing">Enable document indexing</label>
          <Switch
            id="document-indexing"
            checked={docIndexing}
            onChange={(val) => setdocIndexing(val)}
          />
        </div>
        <p className="sub-description">
          Toggle to include or exclude PDF and DOCX documents in the scraping
          results. When enabled, documents will be retrieved during the scraping
          process.
        </p>

        <div className="action-box">
          <Button
            className="back-btn"
            boldText
            type="button"
            onClick={previewHandler}
          >
            Back
          </Button>
          <Button type="submit" boldText invertedTheme isLoading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddUrlForm;
