export const manipulateGenerateUrlFileData = ({ url, fields }) => {
  let file = {
    url,
    key: fields.key ?? "",
    AWSAccessKeyId: fields.AWSAccessKeyId ?? "",
    "x-amz-security-token": fields["x-amz-security-token"] ?? "",
    policy: fields.policy ?? "",
    signature: fields.signature ?? "",
  };
  return file;
};
export const manipulateGenerateUrlData = (requesteddata, payload) => {
  const generatefiles = [];
  const files = payload?.files ?? [];
  files.forEach(({ file_name }) => {
    generatefiles.push(
      manipulateGenerateUrlFileData(requesteddata.files?.[file_name])
    );
  });
  return { files: generatefiles, urls: requesteddata.urls };
};
