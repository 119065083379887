import { PROJECT_SECTIONS } from "../../../../constants";
import CreateProjectListing from "./create-project-listing";
import ProjectsListing from "./projects-listing";

const ProjectSection = ({
  section,
  title,
  desc,
  projectRoles,
  isLoading,
  projects,
  navigateToProject,
}) => {
  return (
    <>
      <div className="intro-box borderless">
        <h1>{title}</h1>
        <p className="section-description">{desc}</p>
      </div>
      {section === PROJECT_SECTIONS.CREATE ? (
        <CreateProjectListing data={projects} />
      ) : (
        <ProjectsListing
          section={section}
          projectRoles={projectRoles}
          projects={projects}
          isLoading={isLoading}
          navigateToProject={navigateToProject}
        />
      )}
    </>
  );
};

export default ProjectSection;
