import React, { useState, useEffect } from "react";
import { Button, Slider } from "../../../";
import { CardBox } from ".";
import { Col, Form, Input, Row, Select } from "antd";
import { getAppEnv, inputFieldRule } from "../../../../utils";
import { MODEL_OPTIONS } from "../../../../constants";
import {
  faDatabase,
  faTemperature3,
  faTerminal,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModelStep = ({
  data = {},
  previousStepHander = () => {},
  nextStepHandler = () => {},
}) => {
  // STATES
  // const [customizePrompt, setcustomizePrompt] = useState(false);
  const [systemPrompt, setSystemPrompt] = useState("");
  // const [example, setExample] = useState("");
  // const [customizeTemperature, setcustomizeTemperature] = useState(false);

  // CONST VALS
  const [form] = Form.useForm();
  const appEnv = getAppEnv();
  const sliderMarks = {
    0: "0.1",
    100: "1.0",
  };

  // HANDLERS
  const sliderFormatter = (value) => (value / 100).toFixed(1);
  // const setcustomizePromptHandler = (val) => {
  //   setcustomizePrompt(val);
  // };
  // const setcustomizeTemperatureHandler = (val) => {
  //   setcustomizeTemperature(val);
  // };
  const setSystemPromptHandler = (e) => {
    setSystemPrompt(e.target.value);
  };
  // const setExampleHandler = (e) => {
  //   setExample(e.target.value);
  // };
  const submitHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const modal = MODEL_OPTIONS.find((item) => item.value === values.model);
        const payload = {
          ...values,
          provider: modal.provider,
          modelName: modal.label,
          customizePrompt: true,
          customizeTemperature: true,
        };
        nextStepHandler(payload);
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => {
    if (!data) return;
    form.setFieldsValue(data);
    // setcustomizePrompt(data?.customizePrompt ?? false);
    // setcustomizeTemperature(data?.customizeTemperature ?? false);
    setSystemPrompt(data?.systemPrompt ?? "");
    // setExample(data?.example ?? "");
  }, [data]);

  // CUSTOM COMPONENT
  const modelDescription = (
    <>
      Please select a model from the options below. If you're uncertain about
      which model is suitable for your project, you can use our
      <a
        className="selected-text"
        href={appEnv.modelComparisionHost}
        rel="noreferrer"
        target="_blank"
      >
        Model Comparison Tool <FontAwesomeIcon icon={faUpRightFromSquare} />
      </a>
      to test the output of different models.
    </>
  );
  return (
    <Form
      form={form}
      className="model-step customize-form"
      initialValues={{
        temperature: 50,
      }}
    >
      {/* VISIBILITY FIELD */}
      <CardBox
        icon={faDatabase}
        title="Choose a Model"
        description={modelDescription}
      >
        <Form.Item
          name="model"
          rules={inputFieldRule({
            name: "Model",
            isWhiteSpace: false,
          })}
        >
          <Select
            options={MODEL_OPTIONS}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select model"
          />
        </Form.Item>
      </CardBox>
      {/* SYSTEM PROMPT FIELD */}
      <CardBox
        icon={faTerminal}
        title="Customize system prompts"
        description="Customizing the system prompt of a model allows users to personalize the predefined input that guides the model's responses, tailoring its behavior to specific preferences or requirements."
      >
        {/* <div className="customize-box">
          <span className="label">Customize</span>
          <Switch
            checked={customizePrompt}
            onChange={setcustomizePromptHandler}
          />
        </div> */}
        <div className="textarea-wrapper labeled">
          <label htmlFor="systemPrompt">
            Please input your system prompt here.
          </label>
          {/* <span
            className={`count-length ${
              systemPrompt.length > 240 ? "error" : ""
            }`}>
            {systemPrompt.length}/240
          </span> */}
          <Form.Item
            name="systemPrompt"
            rules={inputFieldRule({
              name: "System prompt",
              // isMax: true,
              // isRequired: customizePrompt,
              isRequired: false,
              // max: 10000,
            })}
          >
            <Input.TextArea
              // disabled={!customizePrompt}
              rows={3}
              value={systemPrompt}
              onChange={setSystemPromptHandler}
            />
          </Form.Item>
        </div>
        {/* <div className="textarea-wrapper labeled mt-20">
          <label htmlFor="example">
            You can provide questions and answers for the model to use as an
            example here.
          </label>
          <span
            className={`count-length ${example.length > 240 ? "error" : ""}`}>
            {example.length}/240
          </span>
          <Form.Item
            name="example"
            rules={inputFieldRule({
              name: "Example",
              isMax: true,
              isRequired: customizePrompt,
              max: 240,
            })}>
            <Input.TextArea
              disabled={!customizePrompt}
              rows={3}
              value={example}
              onChange={setExampleHandler}
            />
          </Form.Item>
        </div> */}
      </CardBox>
      {/* TEMPERATURE FIELD */}
      <CardBox
        icon={faTemperature3}
        title="Temperature"
        description="Model temperature adjusts the level of randomness in the output, with higher values generating more diverse responses and lower values producing more focused outputs."
      >
        <div className="temperature-field">
          <Row>
            {/* <Col xs={24} md={6}>
              <div className="customize-box">
                <span className="label">Customize</span>
                <Switch
                  checked={customizeTemperature}
                  onChange={setcustomizeTemperatureHandler}
                />
              </div>
            </Col> */}
            <Col xs={24} md={10}>
              <Form.Item
                name="temperature"
                rules={inputFieldRule({
                  name: "Temperature",
                  // isRequired: customizeTemperature,
                  isRequired: false,
                  isWhiteSpace: false,
                })}
              >
                <Slider
                  marks={sliderMarks}
                  // disabled={!customizeTemperature}
                  tooltip={{
                    open: true,
                    placement: "top",
                    getPopupContainer: (triggerNode) => triggerNode.parentNode,
                    formatter: sliderFormatter,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </CardBox>
      <div className="bottom-wrapper">
        <Button className="previous-step-btn" onClick={previousStepHander}>
          Back
        </Button>
        <Button invertedTheme className="next-step-btn" onClick={submitHandler}>
          Next
        </Button>
      </div>
    </Form>
  );
};

export default ModelStep;
