import { call, fork, put, take } from "redux-saga/effects";
import { callRequest, GROUP } from "../../config/web-service";
import { ALERT_TYPES, TOAST_MESSAGES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  createGroupRequest,
  createGroupSuccess,
  deleteGroupRequest,
  deleteGroupSuccess,
  editGroupRequest,
  editGroupSuccess,
  getGroupDetailRequest,
  getGroupDetailSuccess,
  getGroupsRequest,
  getGroupsSuccess,
  addUserInGroupRequest,
  addUserInGroupSuccess,
  removeUserFromGroupRequest,
  removeUserFromGroupSuccess,
  updateGroupProjectRequest,
  updateGroupProjectSuccess,
  removeProjectFromGroupRequest,
  removeProjectFromGroupSuceess,
} from "../slicers/groups";
import {
  manipulateGroupDetailData,
  manipulateGroupsList,
  manipulateGroupsListData,
} from "../../data-manipulator/group";

// GROUPS LISTING
function* getGroupsListing() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getGroupsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      if (response.status) {
        yield put(
          getGroupsSuccess(manipulateGroupsList(response?.data?.groups))
        );
      }
      responseCallback?.(response?.status, response);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GROUP DETAIL
function* getGroupDetail() {
  while (true) {
    const {
      payload: { payload, onlyResponse, responseCallback },
    } = yield take(getGroupDetailRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      if (response.status && !onlyResponse) {
        yield put(
          getGroupDetailSuccess(
            manipulateGroupDetailData(response?.data?.group)
          )
        );
      }
      responseCallback?.(
        response?.status,
        response?.status
          ? manipulateGroupDetailData(response?.data?.group)
          : response
      );

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// CREATE GROUP
function* createGroup() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createGroupRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      const toastMessage = response?.status
        ? TOAST_MESSAGES.GROUP_CREATE_SUCCESS
        : response?.message;

      if (response.status) {
        yield put(
          createGroupSuccess(manipulateGroupsListData(response?.data?.group))
        );
      }
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// UPDATE GROUP
function* updateGroup() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(editGroupRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      const toastMessage = response?.status
        ? TOAST_MESSAGES.GROUP_EDIT_SUCCESS
        : response?.message;

      if (response.status) {
        yield put(
          editGroupSuccess(manipulateGroupsListData(response?.data?.group))
        );
      }
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// DELETE GROUP
function* deleteGroup() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(deleteGroupRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      const toastMessage = response?.status
        ? TOAST_MESSAGES.GROUP_DELETE_SUCCESS
        : response?.message;
      if (response.status) {
        yield put(deleteGroupSuccess(payload.details.id));
      }
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// ADD USER IN GROUP
function* addUserInGroup() {
  while (true) {
    const {
      payload: { payload, isEdit, responseCallback },
    } = yield take(addUserInGroupRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      const successToast = isEdit
        ? TOAST_MESSAGES.EDIT_USER_SUCCESS
        : TOAST_MESSAGES.ADD_USER_SUCCESS;
      const toastMessage = response?.status ? successToast : response?.message;
      if (response.status) {
        yield put(addUserInGroupSuccess(response));
      }
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// REMOVE USER FROM GROUP
function* removeUserFromGroup() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(removeUserFromGroupRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      const toastMessage = response?.status
        ? TOAST_MESSAGES.USER_REMOVE_SUCCESS
        : response?.message;
      if (response.status) {
        yield put(removeUserFromGroupSuccess(payload.details.users[0].user_id));
      }
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// UPDATE GROUP PROJECT
function* updateGroupProject() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(updateGroupProjectRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      const toastMessage = response?.status
        ? TOAST_MESSAGES.PROJECT_UPDATE_SUCCESS
        : response?.message;
      if (response.status) {
        yield put(updateGroupProjectSuccess(payload.details));
      }
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// REMOVE GROUP PROJECT
function* removeGroupProject() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(removeProjectFromGroupRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GROUP,
        payload,
      });
      const toastMessage = response?.status
        ? TOAST_MESSAGES.PROJECT_REMOVE_SUCCESS
        : response?.message;
      if (response.status) {
        yield put(removeProjectFromGroupSuceess(payload.details.project_id));
      }
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}
export default function* root() {
  yield fork(getGroupsListing);
  yield fork(getGroupDetail);
  yield fork(createGroup);
  yield fork(updateGroup);
  yield fork(deleteGroup);
  yield fork(addUserInGroup);
  yield fork(removeUserFromGroup);
  yield fork(updateGroupProject);
  yield fork(removeGroupProject);
}
