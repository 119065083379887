import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  ConfirmationModal,
  Loader,
  PageTitle,
} from "../../../components";
import { CreateGroup } from "../groups/components";
import { ProjectsTable, UserTable } from "./components";
import { CustomDispatch } from "../../../helpers";
import {
  deleteGroupRequest,
  getGroupDetailRequest,
} from "../../../redux/slicers/groups";
import {
  ERROR_ROUTE,
  GROUPS_ROUTE,
  USER_ROLE,
  USER_ROLE_TYPES,
} from "../../../constants";
import "./styles.scss";

const GroupDetail = () => {
  // STATES
  const [editGroupPreview, setEditGroupPreview] = useState(false);
  const [deleteGroupPreview, setdeleteGroupPreview] = useState(false);

  // CUSTOM DISATCH
  const [deleteGroup, deleteLoader] = CustomDispatch(deleteGroupRequest);
  const [getGroupDetail, dataLoader] = CustomDispatch(
    getGroupDetailRequest,
    true
  );

  // REDUX DATA
  const { selectedGroup } = useSelector(({ groups }) => groups);
  const { groupAccess } = useSelector(({ user }) => user);

  // CONST VALS
  const isSuperAdmin = USER_ROLE === USER_ROLE_TYPES.ADMIN;
  const isGroupAdmin = USER_ROLE === USER_ROLE_TYPES.GROUPADMIN || isSuperAdmin;
  const navigate = useNavigate();
  const { groupslug } = useParams();

  // HELPERS
  const getGroupDetailHelper = () => {
    const payload = {
      resource: "group",
      method: "describe",
      details: {
        group_id: groupslug,
      },
    };
    getGroupDetail({
      payload,
      error() {
        navigate(ERROR_ROUTE);
      },
    });
  };

  // HANDLERS
  const closeModalPreviewHandler = () => {
    setEditGroupPreview(false);
    setdeleteGroupPreview(false);
  };
  const deleteMediaHandler = () => {
    const payload = {
      resource: "group",
      method: "delete",
      details: {
        group_id: selectedGroup.id,
      },
    };
    deleteGroup({
      payload,
      success() {
        closeModalPreviewHandler();
        navigate(GROUPS_ROUTE);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (!groupAccess) return navigate(ERROR_ROUTE);
    getGroupDetailHelper();
  }, [groupslug]);

  // show loader while data is fetching
  if (dataLoader) return <Loader height="calc(100vh - 100px)" />;
  // else
  return (
    <div className="group-detail-wrapper">
      <PageTitle
        title={selectedGroup?.name}
        description={selectedGroup?.description}>
        {isSuperAdmin && (
          <div className="action-box">
            <Button onClick={() => setEditGroupPreview(true)}>
              Edit group
            </Button>
            <Button onClick={() => setdeleteGroupPreview(true)}>
              Delete group
            </Button>
          </div>
        )}
      </PageTitle>
      <div className="inner-detail">
        <Row gutter={[0, 40]}>
          {isGroupAdmin && (
            <Col xl={24}>
              <UserTable
                data={selectedGroup?.users}
                isGroupAdmin={isGroupAdmin}
                getGroupDetail={getGroupDetailHelper}
              />
            </Col>
          )}
          <Col xl={24}>
            <ProjectsTable
              group={selectedGroup?.id}
              data={selectedGroup?.projects}
              isGroupAdmin={isGroupAdmin}
            />
          </Col>
        </Row>
      </div>
      <CreateGroup
        isEdit={true}
        data={selectedGroup}
        preview={editGroupPreview}
        previewHandler={closeModalPreviewHandler}
      />
      <ConfirmationModal
        title="Delete Group"
        description="Are you sure you want to delete this group?"
        isLoading={deleteLoader}
        preview={deleteGroupPreview}
        previewHandler={closeModalPreviewHandler}
        confirmBtnHandler={deleteMediaHandler}
      />
    </div>
  );
};

export default GroupDetail;
