import React, { useEffect } from "react";
import { Button, Modal } from "../";
import { Form, Input } from "antd";
import { inputFieldRule } from "../../utils";
import {
  createProjectRequest,
  editProjectRequest,
  restoreProjectRequest,
} from "../../redux/slicers/project";
import { CustomDispatch } from "../../helpers";
import { manipulateCreateProjectData } from "../../data-manipulator/project";
import "./styles.scss";

const ProjectConfirmationModal = ({
  preview = false,
  previewHandler = () => {},
  successHandler = () => {},
  data = {},
  createResponse = {},
}) => {
  // CUSTOM DISPATCH
  const [createProject, createLoader] = CustomDispatch(createProjectRequest);
  const [updateProject, updateLoader] = CustomDispatch(editProjectRequest);
  const [restoreProject, restoreLoader] = CustomDispatch(restoreProjectRequest);
  const [replaceProject, replaceLoader] = CustomDispatch(restoreProjectRequest);

  // CONST VALS
  const [form] = Form.useForm();
  const isLoading = updateLoader || createLoader || replaceLoader;
  const isExistingProject = createResponse?.active;

  // HELPERS
  const createProjectHelper = (values = {}, sucessHandler = () => {}) => {
    const payload = {
      resource: "project",
      method: "create",
      details: manipulateCreateProjectData({ ...data, ...values }),
    };
    createProject({
      payload,
      success: (data) => {
        sucessHandler(data);
      },
    });
  };
  const restoreProjectHelper = (request, sucessHandler = () => {}) => {
    const payload = {
      resource: "project",
      method: "restore",
      details: {
        project_id: createResponse?.project_id,
      },
    };
    request({
      payload,
      success: (data) => {
        sucessHandler(data);
      },
    });
  };
  const updateProjectHelper = (id, sucessHandler = () => {}) => {
    const payload = {
      resource: "project",
      method: "update",
      details: manipulateCreateProjectData({ ...data, id }, true),
    };
    updateProject({
      payload,
      success: (data) => {
        sucessHandler(data);
      },
    });
  };

  // HANDLERS
  const replaceHandler = () => {
    if (isExistingProject) {
      form.validateFields().then((values) => {
        createProjectHelper(values, (res) => {
          successHandler(res);
        });
      });
      return;
    }
    restoreProjectHelper(replaceProject, () => {
      updateProjectHelper(createResponse.project_id, (res) => {
        successHandler(res);
      });
    });
  };
  const restoreHandler = () => {
    restoreProjectHelper(restoreProject, (res) => {
      successHandler(res);
    });
  };

  // HOOKS
  useEffect(() => {
    if (isExistingProject) {
      form.setFieldsValue({ projectName: data.projectName });
    }
  }, [preview, isExistingProject]);

  return (
    <Modal
      openHandler={previewHandler}
      open={preview}
      title={`Project ${isExistingProject ? "Name " : ""}Already Exists`}
      className="project-confirmation-modal">
      {isExistingProject && (
        <Form className="input-box customize-form" form={form}>
          <Form.Item
            name="projectName"
            rules={inputFieldRule({
              name: "Project name",
              isMax: true,
              max: 120,
            })}>
            <Input placeholder="project name" />
          </Form.Item>
        </Form>
      )}
      <div className="bottom-btn">
        {!isExistingProject && (
          <Button
            isLoading={restoreLoader}
            invertedTheme
            className="cancel-btn"
            onClick={restoreHandler}>
            Restore
          </Button>
        )}
        <Button
          invertedTheme
          loaderColor="#000"
          className="confirm-btn"
          isLoading={isLoading}
          onClick={replaceHandler}>
          {isExistingProject ? "Update" : "Replace"}
        </Button>
      </div>
    </Modal>
  );
};

export default ProjectConfirmationModal;
