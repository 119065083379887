import React from "react";
import { ChatBox } from "./components";
import "./styles.scss";

const ProjectDetail = () => {
  return (
    <div className="project-detail">
      <ChatBox />
    </div>
  );
};

export default ProjectDetail;
