import React, { useEffect } from "react";
import { Loader } from "../../components";
import {
  getUserAccessRequest,
  getUserDetailsRequest,
} from "../../redux/slicers/user";
import { getUserAsurite, setAccessToken } from "../../utils";
import { CustomDispatch } from "../../helpers";
import { useSearchParams } from "react-router-dom";

const PrivateSharedLayout = ({ children }) => {
  // CONST VALS
  const [searchParams] = useSearchParams();
  const devToken = searchParams.get("token");

  // CUSTOM DISPATCH
  const [getUserDetail] = CustomDispatch(getUserDetailsRequest);
  const [getUserAccess, acessLoader] = CustomDispatch(
    getUserAccessRequest,
    true
  );

  // HELPERS
  const getUserDetailHelper = () => {
    const userAsurite = getUserAsurite();
    if (userAsurite) {
      const queryParams = { asurite_ids: userAsurite };
      getUserDetail({ loggedinUser: true, queryParams });
    }
  };
  const getUserAccessHelper = () => {
    const payload = {
      resource: "user",
      method: "describe",
    };
    getUserAccess({ payload });
  };

  // HOOKS
  useEffect(() => {
    getUserDetailHelper();
  }, []);

  useEffect(() => {
    async function setAccessTokenHandler() {
      if (!devToken) {
        getUserAccessHelper();
        return;
      }
      await setAccessToken(devToken);
      getUserAccessHelper();
    }
    setAccessTokenHandler();
  }, []);

  if (acessLoader) return <Loader />;
  return children;
};

export default PrivateSharedLayout;
