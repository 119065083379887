import { createSlice } from "@reduxjs/toolkit";
import { cloneDeepItem } from "../../utils";

const GroupsReducer = createSlice({
  name: "groups",
  initialState: {
    groups: [],
    selectedGroup: null,
  },
  reducers: {
    // CREATE GROUP
    createGroupRequest() {},
    createGroupSuccess(state, action) {
      state.groups = [action.payload, ...state.groups];
    },

    // GROUPS LISTING
    getGroupsRequest() {},
    getGroupsSuccess(state, action) {
      state.groups = action.payload;
    },

    // EDIT GROUP
    editGroupRequest() {},
    editGroupSuccess(state, { payload }) {
      const temp = cloneDeepItem(state.groups);
      const index = temp.findIndex((item) => item.id === payload.id);
      temp[index] = payload;
      state.groups = temp;
      state.selectedGroup = payload;
    },

    // EDIT GROUP TPM
    editGroupTPMRequest(state, action) {
      const { payload } = action.payload;
      const temp = cloneDeepItem(state.groups);
      const index = temp.findIndex((item) => item.id === payload.id);
      temp[index] = payload;
      state.groups = temp;
      state.selectedGroup = payload;
    },

    // DELETE GROUP
    deleteGroupRequest() {},
    deleteGroupSuccess(state, { payload }) {
      const temp = cloneDeepItem(state.groups);
      const index = temp.findIndex((item) => item.id === payload);
      temp.splice(index, 1);
      state.groups = temp;
    },

    // GROUP DETAIL
    getGroupDetailRequest() {},
    getGroupDetailSuccess(state, { payload }) {
      state.selectedGroup = payload;
    },

    // ADD USERS IN GROUP
    addUserInGroupRequest() {},
    addUserInGroupSuccess(state, { payload }) {},

    // REMOVE USER FROM GROUP
    removeUserFromGroupRequest() {},
    removeUserFromGroupSuccess(state, { payload }) {
      const temp = cloneDeepItem(state.selectedGroup);
      const users = temp.users.filter((item) => item.id !== payload);
      temp.users = users;
      state.selectedGroup = temp;
    },

    updateGroupProjectRequest() {},
    updateGroupProjectSuccess(state, { payload }) {
      const temp = cloneDeepItem(state.selectedGroup);
      const project = temp.projects.find(
        (item) => item.id === payload.project_id
      );
      project.tpm = payload.tpm;
      state.selectedGroup = temp;
    },
    removeProjectFromGroupRequest() {},
    removeProjectFromGroupSuceess(state, { payload }) {
      const temp = cloneDeepItem(state.selectedGroup);
      const projects = temp.projects.filter((item) => item.id !== payload);
      temp.projects = projects;
      state.selectedGroup = temp;
    },
  },
});

export const {
  createGroupRequest,
  createGroupSuccess,
  getGroupsRequest,
  getGroupsSuccess,
  editGroupRequest,
  editGroupSuccess,
  editGroupTPMRequest,
  deleteGroupRequest,
  deleteGroupSuccess,
  getGroupDetailRequest,
  getGroupDetailSuccess,
  addUserInGroupRequest,
  addUserInGroupSuccess,
  removeUserFromGroupRequest,
  removeUserFromGroupSuccess,
  updateGroupProjectRequest,
  updateGroupProjectSuccess,
  removeProjectFromGroupRequest,
  removeProjectFromGroupSuceess,
} = GroupsReducer.actions;

export default GroupsReducer.reducer;
