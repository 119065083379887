import React from "react";
import { Form, Input, Select, Slider, Switch, Tooltip } from "antd";
import { LabelBox } from "..";
import {
  inputFieldRule,
  temperatureSliderformatter,
} from "../../../../../../utils";
import {
  MODEL_OPTIONS,
  TEMPERATURE_MARKS,
  VERBOSITY_OPTIONS,
} from "../../../../../../constants";
import { ExpandField } from "../../../../../../components";
import { TIME_ZONES } from "../../../../../../constants/timezones";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUndo } from "@fortawesome/free-solid-svg-icons";

const BasicFields = ({ switchFields, switchFieldsHandler }) => {
  return (
    <>
      {/* PROJECT NAME */}
      <LabelBox
        title="Project name"
        name="projectName"
        message="Name of project"
      />
      <Form.Item
        name="projectName"
        id="projectName"
        rules={inputFieldRule({
          name: "Project name",
          isMax: true,
          max: 120,
        })}
      >
        <Input />
      </Form.Item>
      {/* PROJECT MODEL */}
      <div className="label-box">
        <LabelBox
          heading
          title="Model"
          // message="Enable advanced setting."
        />
        <span className="helper">
          <label htmlFor="advanced" className="helper-text">
            Advanced settings
          </label>
          <Tooltip
            title={`${
              switchFields.advanced ? "Disable" : "Enable"
            } advanced option`}
          >
            <Switch
              id="advanced"
              checked={switchFields.advanced}
              onChange={() => switchFieldsHandler("advanced")}
            />
          </Tooltip>
        </span>
      </div>
      <LabelBox
        title="Choose a Model"
        name="model"
        message="Select a model for your project"
      />
      <Form.Item
        name="model"
        rules={inputFieldRule({
          name: "Model",
          isWhiteSpace: false,
        })}
      >
        <Select
          showSearch
          id="model"
          options={MODEL_OPTIONS}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select model"
        />
      </Form.Item>
      {/* CUSTOMIZE INSTRUCTION */}
      <LabelBox
        name="customInstructions"
        title="Custom instructions"
        message="
            Custom instructions let you personalize how the AI responds by
            specifying your preferences or providing context. This helps the AI
            tailor its behavior, making responses more relevant and aligned with
            your specific needs, improving overall efficiency and satisfaction."
      />
      <ExpandField title="System prompt">
        <Form.Item
          name="systemPrompt"
          rules={inputFieldRule({
            name: "System prompt",
            isRequired: false,
          })}
        >
          <Input.TextArea
            rows={5}
            placeholder="Please input your system prompt here"
          />
        </Form.Item>
      </ExpandField>
      {switchFields.advanced && (
        <>
          <LabelBox
            title="Temperature"
            name="temperature"
            message="Temperature controls the randomness of AI responses. Lower values make outputs more focused and deterministic, while higher values generate more varied and creative responses."
          />
          <p className="sub-description" style={{ marginBottom: 25 }}>
            Temperature controls how creative or focused the AI's responses are.
            A lower temperature makes answers more predictable, while a higher
            temperature makes them more diverse and creative.
          </p>
          <Form.Item
            id="temperature"
            name="temperature"
            rules={inputFieldRule({
              name: "Temperature",
              isWhiteSpace: false,
            })}
          >
            <Slider
              className="temperature-slider"
              marks={TEMPERATURE_MARKS}
              tooltip={{
                open: true,
                placement: "top",
                getPopupContainer: (triggerNode) => triggerNode.parentNode,
                formatter: temperatureSliderformatter,
              }}
            />
          </Form.Item>
          {/* ENHANCED PROMPT */}
          <LabelBox title="Enhanced prompt" name="enhancedPrompt" />
          <p className="sub-description">
            These parameters are not specific to the model or search settings,
            but are required unless a default value is provided.
          </p>
          <LabelBox
            title="Timezone"
            name="timezone"
            message="Select your time zone to ensure date and time-related responses are accurate. This helps the AI tailor outputs to your local time settings."
          />
          <Form.Item
            name="timezone"
            rules={inputFieldRule({
              name: "Timezone",
              isWhiteSpace: false,
              isRequired: false,
            })}
          >
            <Select
              showSearch
              id="timezone"
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select timezone"
            >
              {TIME_ZONES.map((item, index) => (
                <Select.Option key={index} value={item.utc[0]}>
                  {item.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="label-box form-item lg">
            <LabelBox
              name="time"
              title="Time"
              message="Determine whether the model considers the current time in its responses."
            />
            <Switch
              id="time"
              checked={switchFields.time}
              onChange={() => switchFieldsHandler("time")}
            />
          </div>
          <div className="label-box form-item lg">
            <LabelBox
              name="date"
              title="Date"
              message="Determine whether the model considers the current time in its responses."
            />
            <Switch
              id="date"
              checked={switchFields.date}
              onChange={() => switchFieldsHandler("date")}
            />
          </div>
          <LabelBox
            title="Verbosity"
            name="verbosity"
            message="Adjust the level of detail in the AI's responses. Set to brief, detailed, or succinct to control how much information the model provides in each reply."
          />
          <Form.Item
            name="verbosity"
            rules={inputFieldRule({
              name: "Verbosity",
              isWhiteSpace: false,
              isRequired: false,
            })}
          >
            <Select
              id="verbosity"
              options={VERBOSITY_OPTIONS}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select verbosity"
            />
          </Form.Item>
          {/* ENHANCED PROMPT */}
          <div className="label-box">
            <LabelBox
              title="Enable response caching"
              name="enableCaching"
              message="Enable this option to cache AI responses, allowing quicker access to previously generated replies within the same conversation for improved efficiency."
            />
            <Switch
              id="enableCaching"
              checked={switchFields.caching}
              onChange={() => switchFieldsHandler("caching")}
            />
          </div>
        </>
      )}
      {/* <button className="reset-btn" type="button">
        <FontAwesomeIcon icon={faUndo} />
        Reset custom instructions to ASU default
      </button> */}
    </>
  );
};

export default BasicFields;
