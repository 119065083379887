import React from "react";
import { toastAlert, validateFile } from "../../utils";
import { Button } from "../";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { ALERT_TYPES, DROPZONE_FILE_VALIDATION } from "../../constants";
import "./styles.scss";

const UploadBox = ({
  uploadFile = () => {},
  fileValidation = DROPZONE_FILE_VALIDATION,
}) => {
  // HANDLERS
  const uploadFileHandler = (files) => {
    const validFiles = [];
    files.forEach((file) => {
      if (validateFile(file, false)) {
        validFiles.push(file);
      }
    });
    validFiles.length >= 1 && uploadFile(validFiles);
  };
  // CONST VALS
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    maxFiles: 300,
    accept: fileValidation,
    onDropAccepted: (file) => {
      uploadFileHandler(file);
    },
    onDropRejected: () => toastAlert("Invalid file type", ALERT_TYPES.ERROR),
  });
  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className={`upload-box ${isDragActive ? "active" : ""}`}>
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faCloudArrowUp} className="thumb" />
      {isDragActive ? (
        <p>Drop the files here</p>
      ) : (
        <>
          <p>Drag a file here to upload or</p>
          <Button invertedTheme boldText onClick={open}>
            Browse files
          </Button>
        </>
      )}
    </div>
  );
};

export default UploadBox;
