import React, { useState, useEffect } from "react";
import { safeHtmlString } from "../../utils";

const ContentEditable = ({
  value,
  className,
  innerEditableRef,
  inputRef,
  change,
  remove,
  validator,
  removeOnBackspace,
}) => {
  // STATES
  // const [focused, setFocused] = useState(false);
  const [preFocusedValue, setPreFocusedValue] = useState(value);
  const [removed, setRemoved] = useState(false);

  // HANDLERS
  const onPaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(document.createTextNode(safeHtmlString(text)));
  };
  const onFocus = () => {
    setPreFocusedValue(getValue());
  };
  const onBlur = () => {
    const ref = innerEditableRef.current;
    if (!removed && ref) {
      if (ref.innerText === "") {
        remove();
        return;
      }
      if (validator) {
        const valid = validator(getValue());
        if (!valid) {
          ref.innerText = preFocusedValue;
          return;
        }
      }
      change(ref.innerText);
    }
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      focusInputRef();
      return;
    }

    if (removeOnBackspace && e.keyCode === 8 && getValue() === "") {
      setRemoved(true);
      remove();
      focusInputRef();
      return;
    }
  };
  const getValue = () => {
    const ref = innerEditableRef.current;
    return ref ? ref.innerText : "";
  };
  const focusInputRef = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  // HOOKS
  useEffect(() => {
    setPreFocusedValue(value);
  }, [value]);

  return (
    <button
      ref={innerEditableRef}
      className={className}
      contentEditable={true}
      onPaste={onPaste}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      dangerouslySetInnerHTML={{ __html: safeHtmlString(value) }}
    />
  );
};

export default ContentEditable;
