import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PROJECT_ROUTE } from "../../../../constants";
import {
  faCommentAlt,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  deleteChatRequest,
  getChatsRequest,
  removeTextAnimation,
  updateChatRequest,
} from "../../../../redux/slicers/chat";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { CustomDispatch } from "../../../../helpers";
import { ConfirmationModal, Loader } from "../../../../components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { Input } from "antd";
import {
  faChevronLeft,
  faChevronRight,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const ChatSidebar = ({ preview, previewHandler }) => {
  // STATES
  const [moreChatsData, setmoreChatsData] = useState(null);
  const [deleteChatPreview, setDeleteChatPreview] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");

  // CUSTOM DISPATCH
  const [getChats, chatLoader] = CustomDispatch(getChatsRequest);
  const [deleteChat, deleteLoader] = CustomDispatch(deleteChatRequest);
  const [updateChat] = CustomDispatch(updateChatRequest);

  // REDUX DATA
  const chats = useSelector(({ chat }) => chat.chats);

  // CONST VALS
  const navigate = useNavigate();
  const { projectslug: projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentChat = searchParams.get("chat");
  const chatCategories = [...new Set(chats.map((chat) => chat.category))];
  const dispatch = useDispatch();
  const editInputRef = useRef(null);

  // HELPERS
  const getChatsHelper = () => {
    const payload = {
      method: "list",
      details: {
        app_id: projectId,
        archive: false,
      },
      item_limit: 50,
    };
    if (moreChatsData) payload["next_page"] = moreChatsData;
    getChats({
      payload,
      success: (data) => setmoreChatsData(data?.next_page),
    });
  };

  // HANDLERS
  const handleScroll = (e) => {
    if (e.target.scrollTop + e.target.clientHeight !== e.target.scrollHeight)
      return;
    if (moreChatsData && !chatLoader) getChatsHelper();
  };

  const removeAnimationHandler = (id) => {
    dispatch(removeTextAnimation(id));
  };

  const editChatPreviewHandler = (e, index, item) => {
    e.stopPropagation();
    setSelectedChat(item.id);
    setEditInputValue(item.name);
    setEditInputIndex(item.id);
  };

  const updateChatHandler = () => {
    const oldVal = chats.find((x) => x.id === editInputIndex).name;
    setEditInputIndex(-1);
    if (editInputValue === oldVal || editInputValue === "") return;
    const payload = {
      method: "update",
      details: {
        app_id: projectId,
        session_id: selectedChat,
        session_name: editInputValue,
      },
    };
    updateChat({ payload });
    setEditInputValue("");
    setSelectedChat(null);
  };

  const deleteChatPreviewHandler = (e, id) => {
    e.stopPropagation();
    setSelectedChat(id);
    setDeleteChatPreview(true);
  };

  const deleteChatHandler = () => {
    const payload = {
      method: "delete",
      details: {
        app_id: projectId,
        session_id: selectedChat,
      },
    };
    deleteChat({
      payload,
      success() {
        setDeleteChatPreview(false);
        if (currentChat !== selectedChat) return;
        const projectRoute = PROJECT_ROUTE.replace(":projectslug", projectId);
        navigate(projectRoute);
      },
    });
  };

  useEffect(() => {
    getChatsHelper();
  }, []);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputIndex]);
  return (
    <nav
      role="navigation"
      className={clsx("chat-sidebar", preview && "active")}
    >
      <button className="sidebar-toggle" onClick={previewHandler}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      {chatLoader && !moreChatsData ? (
        <Loader height="100%" size={12} />
      ) : chats.length >= 1 ? (
        <div className="chats-listing customize-form" onScroll={handleScroll}>
          {chatCategories.map((chat, index) => {
            const catChats = chats?.filter((item) => item.category === chat);
            return (
              <React.Fragment key={index}>
                <button className="chat-title">{chat}</button>
                {catChats.map((item, i) => {
                  const itemIndex = `${index}-${i}`;
                  const isEdit = editInputIndex === item.id;
                  return (
                    <button
                      key={itemIndex}
                      onClick={() =>
                        !isEdit && setSearchParams({ chat: item.id })
                      }
                      className={clsx(
                        `chat-item`,
                        currentChat === item.id && "active"
                      )}
                    >
                      <FontAwesomeIcon className="thumb" icon={faCommentAlt} />
                      <span className="title">
                        {isEdit ? (
                          <Input
                            ref={editInputRef}
                            key={itemIndex}
                            size="small"
                            value={editInputValue}
                            onChange={(e) => setEditInputValue(e.target.value)}
                            onBlur={updateChatHandler}
                            onPressEnter={updateChatHandler}
                          />
                        ) : (
                          <>
                            {item.isAnimated ? (
                              <ReactTyped
                                strings={[item.name]}
                                typeSpeed={25}
                                onComplete={() =>
                                  removeAnimationHandler(item.id)
                                }
                                showCursor={false}
                              />
                            ) : (
                              item.name
                            )}
                          </>
                        )}
                      </span>
                      {!isEdit && (
                        <span className="action-box">
                          <FontAwesomeIcon
                            onClick={(e) =>
                              editChatPreviewHandler(e, itemIndex, item)
                            }
                            className="edit-btn"
                            icon={faEdit}
                          />
                          <FontAwesomeIcon
                            onClick={(e) =>
                              deleteChatPreviewHandler(e, item.id)
                            }
                            className="delete-btn"
                            icon={faTrashAlt}
                          />
                        </span>
                      )}
                    </button>
                  );
                })}
              </React.Fragment>
            );
          })}
          {moreChatsData && chatLoader && (
            <Loader height="30px" size={10} style={{ marginTop: "10px" }} />
          )}
        </div>
      ) : (
        <div className="empty-box">
          <FontAwesomeIcon className="thumb" icon={faComments} />
          <h4>No conversations yet</h4>
        </div>
      )}
      <ConfirmationModal
        preview={deleteChatPreview}
        isLoading={deleteLoader}
        title="Delete Chat"
        description="Are you sure you want to delete this chat?"
        confirmBtnText="Delete"
        confirmBtnHandler={deleteChatHandler}
        previewHandler={() => setDeleteChatPreview(false)}
      />
    </nav>
  );
};

export default ChatSidebar;
