import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { Table, ConfirmationModal, Slider } from "../../../../components";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomDispatch } from "../../../../helpers";
import { PROJECT_ROUTE } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import {
  updateGroupProjectRequest,
  removeProjectFromGroupRequest,
} from "../../../../redux/slicers/groups";

const ProjectsTable = ({ isGroupAdmin, data = [], group }) => {
  // STATES
  const [filteredData, setfilteredData] = useState([]);
  const [removeProjectPreview, setRemoveProjectPreview] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  // CUSTOM DISPATCH
  const [updateProject] = CustomDispatch(updateGroupProjectRequest);
  const [removeProject, removeLoader] = CustomDispatch(
    removeProjectFromGroupRequest
  );

  // CONST VALS
  const marks = {
    0: "1k",
    100: "300k",
  };

  // HANDLERS
  const generateTableColumn = (data) => {
    const columns = [];
    data.forEach((element) => {
      if (element.isGroupAdmin && !isGroupAdmin) return;
      columns.push(element);
    });
    return columns;
  };
  const searchHandler = (e) => {
    const { value } = e.target;
    if (value === "") return setfilteredData(data);
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setfilteredData(filtered);
  };
  const removeProjectPreviewHandler = (data) => {
    setRemoveProjectPreview(true);
    setSelectedData(data);
  };
  const removeProjectHandler = () => {
    const payload = {
      resource: "project",
      method: "delete",
      details: {
        project_id: selectedData.id,
        group_id: group,
      },
    };
    removeProject({
      payload,
      success() {
        setRemoveProjectPreview(false);
      },
    });
  };
  const editTpmHandler = (data, value) => {
    const payload = {
      resource: "project",
      method: "update",
      details: {
        project_id: data.id,
        tpm: value,
      },
    };
    updateProject({
      payload,
    });
  };

  // CONST VALS
  const navigate = useNavigate();
  const tableColumns = generateTableColumn([
    {
      title: "Name",
      dataIndex: "name",
      onCell: (record) => {
        return {
          onClick: () =>
            navigate(PROJECT_ROUTE.replace(":projectslug", record.id)),
        };
      },
    },
    {
      title: "Model",
      dataIndex: "modelLabel",
    },
    {
      title: "Tokens Per Minute (thousands)",
      isGroupAdmin: true,
      dataIndex: "",
      render: (data) => {
        return (
          <span className="customize-form">
            <Slider
              min={1}
              max={300}
              marks={marks}
              val={data.tpm}
              onAfterChange={(val) => {
                editTpmHandler(data, val);
              }}
            />
          </span>
        );
      },
    },
    {
      title: "",
      isGroupAdmin: true,
      dataIndex: "",
      render: (data) => {
        return (
          <span className="actions-box">
            <Tooltip title="Remove project">
              <FontAwesomeIcon
                onClick={() => removeProjectPreviewHandler(data)}
                className="delete"
                icon={faTrashAlt}
              />
            </Tooltip>
          </span>
        );
      },
    },
  ]);

  // HOOKS
  useEffect(() => {
    setfilteredData(data);
  }, [data]);

  return (
    <>
      <Table
        title="Projects"
        bordered
        isSearch
        columns={tableColumns}
        data={filteredData}
        searchHandler={searchHandler}
      />
      <ConfirmationModal
        title="Remove Project"
        description="Are you sure you want to remove this project from group?"
        isLoading={removeLoader}
        preview={removeProjectPreview}
        previewHandler={() => setRemoveProjectPreview(false)}
        confirmBtnHandler={removeProjectHandler}
      />
    </>
  );
};

export default ProjectsTable;
