import { fork } from "redux-saga/effects";
import general from "./general";
import user from "./user";
import project from "./project";
import group from "./group";
import chats from "./chats"

export default function* root() {
  yield fork(general);
  yield fork(user);
  yield fork(project);
  yield fork(group);
  yield fork(chats);
}
