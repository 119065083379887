import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";

const root = createRoot(document.getElementById("root"));

if (process.env.REACT_APP_ENV === "development") {
  const axe = require("@axe-core/react");
  axe(React, root, 1000);
}
root.render(<App />);

reportWebVitals();
