import React, { useEffect, useState } from "react";
import { Button } from "../../../";
import { CardBox } from ".";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { Form, Input, Select } from "antd";
import { inputFieldRule } from "../../../../utils";
import {
  USE_CASES_OPTIONS,
  // VISIBILITY_OPTIONS,
} from "../../../../constants";
import {
  faRing,
  // faUserGroup,
  // faUserPen,
} from "@fortawesome/free-solid-svg-icons";
// import { useSelector } from "react-redux";
// import { getGroupsRequest } from "../../../redux/slicers/groups";
// import { CustomDispatch } from "../../../helpers";

const ProjectStep = ({
  data = {},
  previousStepHander = () => {},
  nextStepHandler = () => {},
}) => {
  // STATES
  const [description, setDescription] = useState("");

  // CUSTOM DISATCH
  // const [getGroups, groupsLoader] = CustomDispatch(getGroupsRequest);

  // REDUX DATA
  // const { groups } = useSelector(({ groups }) => groups);

  // CONST VALS
  const [form] = Form.useForm();

  // HANDLERS
  const setDescriptionHandler = (e) => {
    setDescription(e.target.value);
  };
  // const filterCollaborators = (input, option) => {
  //   return (option?.children ?? "").toLowerCase().includes(input.toLowerCase());
  // };
  const submitHandler = () => {
    form
      .validateFields()
      .then((values) => {
        nextStepHandler(values);
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => {
    if (data) {
      setDescription(data?.projectDescription ?? "");
      form.setFieldsValue(data);
    }
  }, [data]);

  // useEffect(() => {
  //   const payload = {
  //     resource: "group",
  //     method: "list",
  //   };
  //   getGroups({
  //     payload,
  //   });
  // }, []);

  return (
    <Form form={form} className="project-step customize-form">
      {/* NAME FIELD */}
      <CardBox
        icon={faFolderOpen}
        title="Project name"
        description="Create a name for your project">
        <Form.Item
          name="projectName"
          rules={inputFieldRule({
            name: "Project name",
            isMax: true,
            max: 120,
          })}>
          <Input />
        </Form.Item>
      </CardBox>
      {/* DESCRIPTION FIELD */}
      <CardBox
        icon={faFolderOpen}
        title="Project description"
        description="Please write a brief description of your project">
        <div className="textarea-wrapper">
          <span
            className={`count-length ${
              description.length > 240 ? "error" : ""
            }`}>
            {description.length}/240
          </span>
          <Form.Item
            name="projectDescription"
            rules={inputFieldRule({
              name: "Project description",
              isMax: true,
              max: 240,
            })}>
            <Input.TextArea
              rows={3}
              value={description}
              onChange={setDescriptionHandler}
            />
          </Form.Item>
        </div>
      </CardBox>
      {/* VISIBILITY FIELD */}
      {/* <CardBox
        icon={faEye}
        title="Visibility"
        description="Select who has access view to this project">
        <Form.Item
          name="visibility"
          rules={inputFieldRule({
            name: "Visibility",
            isWhiteSpace: false,
          })}>
          <Select
            options={VISIBILITY_OPTIONS}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select visibility"
          />
        </Form.Item>
      </CardBox> */}
      {/* GROUP FIELD */}
      {/* <CardBox
        icon={faUserGroup}
        title="Group"
        description="Select the group with which the project is associated">
        <Form.Item
          name="group"
          rules={inputFieldRule({
            name: "Group",
            isWhiteSpace: false,
          })}>
          <Select
            loading={groupsLoader}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select group">
            {groups.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </CardBox> */}
      {/* USE CASES FIELD */}
      <CardBox
        icon={faRing}
        title="Use cases"
        description="Select your use case">
        <Form.Item
          name="usecase"
          rules={inputFieldRule({
            name: "Use case",
            isWhiteSpace: false,
          })}>
          <Select
            options={USE_CASES_OPTIONS}
            mode="multiple"
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select use case"
          />
        </Form.Item>
      </CardBox>
      <div className="bottom-wrapper">
        {/* <Button className="previous-step-btn" onClick={previousStepHander}>
          Back
        </Button> */}
        <Button invertedTheme className="next-step-btn" onClick={submitHandler}>
          Next
        </Button>
      </div>
    </Form>
  );
};

export default ProjectStep;
