import React, { useState } from "react";
import DetailAccordion from "./detail-accordion";
// import ModelAccordion from "./model-accordion";
import KnowledgeBaseAccordion from "./knowledge-base-accordion";
import InterfaceAccordion from "./interface-accordion";
import DeleteProject from "./delete-project";
import "./styles.scss";

const AdvancedTab = ({ changeTab }) => {
  // STATES
  const [deletePreview, setdeletePreview] = useState(false);

  // HANDLERS
  const deleteProjectPreviewHandler = () => setdeletePreview(!deletePreview);

  return (
    <div className="advance-tab">
      <DetailAccordion
        changeTab={changeTab}
        deleteHandler={deleteProjectPreviewHandler}
      />
      {/* <ModelAccordion changeTab={changeTab} /> */}
      <KnowledgeBaseAccordion changeTab={changeTab} />
      <InterfaceAccordion changeTab={changeTab} />
      {/* DELETE PROJECT MODAL */}
      <DeleteProject
        preview={deletePreview}
        closeHandler={deleteProjectPreviewHandler}
      />
    </div>
  );
};

export default AdvancedTab;
