import { call, takeEvery } from "redux-saga/effects";
import { callRequest, UPLOAD_MEDIA, PROJECT } from "../../config/web-service";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  generateUploadURLRequest,
  uploadMediaRequest,
} from "../slicers/general";
import { manipulateGenerateUrlData } from "../../data-manipulator/general";

// GENERATE UPLOAD URL
function* generateUploadUrl(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    if (response.status) {
      responseCallback?.(
        true,
        manipulateGenerateUrlData(response.data, payload.details)
      );
      return;
    }
    responseCallback?.(false, response);
    toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// UPLOAD MEDIA
function* uploadMedia(action) {
  const {
    payload: { url, payload, responseCallback },
  } = action;

  try {
    const urlobj = { ...UPLOAD_MEDIA, baseurl: url };
    const response = yield call(callRequest, {
      url: urlobj,
      payload,
    });
    responseCallback?.(true, response);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

export default function* root() {
  yield takeEvery(generateUploadURLRequest.type, generateUploadUrl);
  yield takeEvery(uploadMediaRequest.type, uploadMedia);
}
