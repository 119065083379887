import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  ACCESS_TYPES,
  DASHBOARD_ROUTE,
  // PROJECT_ANALYTICS_ROUTE,
  PROJECT_ROUTE,
  GROUPS_ROUTE,
  GROUP_DETAIL_ROUTE,
  LEARNING_ROUTE,
  PROJECTS_ROUTE,
  MARKETPLACE_ROUTE,
  APIDOC_ROUTE,
} from "../constants";
import Helmet from "react-helmet";
import {
  PrivateRoute,
  PublicRoute,
  AuthRoute,
  DashboardRoute,
  ProjectRoute,
} from "../config/routes-handling";
import {
  Error,
  Dashboard,
  ProjectDetail,
  Groups,
  GroupDetail,
  Learning,
  Projects,
  MarketPlace,
  ApiDoc,
} from "../modules";

const renderRouteSharedLayout = (title, description, access, component) => (
  <React.Fragment>
    <Helmet>
      <title>{title ? `${title} |` : ""} ASU AI Platform</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
    {access === ACCESS_TYPES.AUTH ? (
      <AuthRoute> {component}</AuthRoute>
    ) : access === ACCESS_TYPES.PRIVATE ? (
      <PrivateRoute>{component}</PrivateRoute>
    ) : access === ACCESS_TYPES.DASHBOARD ? (
      <DashboardRoute>{component}</DashboardRoute>
    ) : access === ACCESS_TYPES.PROJECT ? (
      <ProjectRoute>{component}</ProjectRoute>
    ) : (
      <PublicRoute>{component}</PublicRoute>
    )}
  </React.Fragment>
);

const PageRoutes = () => {
  // CONST VALS
  const location = useLocation();

  const ROUTES = [
    // PUBLIC ROUTES

    // AUTH ROUTES

    // PRIVATE ROUTE
    {
      route: APIDOC_ROUTE,
      title: "Dashboard",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <ApiDoc />,
    },
    {
      route: DASHBOARD_ROUTE,
      title: "Dashboard",
      description: "",
      access: ACCESS_TYPES.DASHBOARD,
      component: <Dashboard />,
    },
    {
      route: PROJECTS_ROUTE,
      title: "MyAI Projects",
      description: "",
      access: ACCESS_TYPES.DASHBOARD,
      component: <Projects />,
    },
    {
      route: PROJECT_ROUTE,
      title: "Project detail",
      description: "",
      access: ACCESS_TYPES.PROJECT,
      component: <ProjectDetail />,
    },
    {
      route: GROUPS_ROUTE,
      title: "Groups",
      description: "",
      access: ACCESS_TYPES.DASHBOARD,
      component: <Groups />,
    },
    {
      route: GROUP_DETAIL_ROUTE,
      title: "Group detail",
      description: "",
      access: ACCESS_TYPES.DASHBOARD,
      component: <GroupDetail />,
    },
    {
      route: MARKETPLACE_ROUTE,
      title: "MyAI Marketplace",
      description: "",
      access: ACCESS_TYPES.DASHBOARD,
      component: <MarketPlace />,
    },
    {
      route: LEARNING_ROUTE,
      title: "Learning",
      description: "",
      access: ACCESS_TYPES.DASHBOARD,
      component: <Learning />,
    },
  ];

  // HOOKS
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        {ROUTES.map((item, index) => (
          <Route
            path={item.route}
            element={renderRouteSharedLayout(
              item.title,
              item.description,
              item.access,
              item.component
            )}
            key={index}
          />
        ))}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
