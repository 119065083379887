import React, { useState, useEffect } from "react";
import { Form, Input, Radio, Select, Slider, Switch } from "antd";
import { AccordionBox, FormActions, LabelBox } from "..";
import { useSelector } from "react-redux";
import {
  editProjectRequest,
  getProjectMediaRequest,
} from "../../../../../../redux/slicers/project";
import { inputFieldRule, topKSliderformatter } from "../../../../../../utils";
import {
  MEDIA_RETRIEVAL_OPTIONS,
  TOPK_MARKS,
} from "../../../../../../constants";
import { CustomDispatch } from "../../../../../../helpers";
import { manipulateProjectMediaPayload } from "../../../../../../data-manipulator/project";
import { USED_MEDIA_OPTIONS } from "../../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const KnowledgeBaseAccordion = ({ changeTab }) => {
  // STATES
  const [switchFields, setswitchFields] = useState({
    // enableSearch: false,
    showSources: false,
    // advancedOptions: false,
  });
  const [resetPreview, setResetPreview] = useState(false);
  const [applyPreview, setapplyPreview] = useState(false);
  const [usedMediaPreview, setusedMediaPreview] = useState(false);
  const [selectedUsedMedia, setselectedUsedMedia] = useState(
    USED_MEDIA_OPTIONS[0]
  );

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  // const projectMedia = useSelector(({ project }) => project.projectMedia);

  // CUSTOM DISPATCH
  const [getProjectMedia] = CustomDispatch(getProjectMediaRequest);
  const [editProject, isLoading] = CustomDispatch(editProjectRequest);

  // CONST VALS
  const [form] = Form.useForm();

  // HELPERS
  const setFieldsValuesHelper = () => {
    setswitchFields({
      // enableSearch: data.enableSearch,
      showSources: data.showSources,
      // advancedOptions: data.advancedOptions,
    });
    setselectedUsedMedia(data?.promptModeLabel);
    form.setFieldsValue(data);
  };
  const getProjectMediaHelper = () => {
    const payload = {
      resource: "data",
      method: "list",
      details: {
        project_id: data.id,
        db_type: data.database,
      },
    };
    getProjectMedia({ payload });
  };
  const editProjectHelper = (values = {}) => {
    const details = {
      ...data,
      ...values,
      ...switchFields,
      promptMode: selectedUsedMedia.value,
    };
    const payload = {
      resource: "project",
      method: "update",
      details: manipulateProjectMediaPayload(details),
    };
    editProject({
      payload,
      callback: (status) => fieldChangeHandler(!status),
    });
  };

  // HANDLERS
  const fieldChangeHandler = (status = true) => {
    setResetPreview(status);
    setapplyPreview(status);
  };
  const setswitchFieldsHandler = (name) => {
    fieldChangeHandler();
    setswitchFields({ ...switchFields, [name]: !switchFields[name] });
  };
  const selectUsedModelHandler = (value) => {
    setusedMediaPreview(false);
    setselectedUsedMedia(value);
    fieldChangeHandler();
  };
  const resetHandler = () => {
    setFieldsValuesHelper();
    fieldChangeHandler(false);
  };
  const applyHandler = () => {
    form
      .validateFields()
      .then((values) => {
        fieldChangeHandler(false);
        editProjectHelper(values);
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => {
    getProjectMediaHelper();
  }, []);

  useEffect(() => {
    if (data) setFieldsValuesHelper(true);
  }, [data]);

  return (
    <AccordionBox
      title="Knowledge base"
      className="knowledge-base-accordion customize-form"
    >
      {/* MODEL BASIC SETTING */}
      {/* <div className="basic-settings">
        <h3 className="title">
          Basic settings
          <button type="button" onClick={changeTab}>
            Edit
          </button>
        </h3>
        <div className="detail inline">
          <p>Uploads: </p>
          <h4 className="theme-text underline">
            ({projectMedia?.files?.length}) files uploaded, (
            {projectMedia?.urls?.length}) URL's
          </h4>
        </div>
      </div>
      <hr /> */}
      <Form form={form}>
        {/* ENABLE MEDIA */}
        {/* <div className="label-box">
          <LabelBox
            name="enableMedia"
            title="Enable assets uploads"
            message="Enabling this will restrict the model to only answering questions from your files."
          />
          <Switch
            id="enableMedia"
            checked={switchFields.enableSearch}
            onChange={() => setswitchFieldsHandler("enableSearch")}
          />
        </div> 
        <hr /> */}
        <div className="label-box">
          <LabelBox
            name="showSources"
            title="Show citations"
            message="Enable this option to include citations in the AI's responses, providing sources and references for the information provided."
          />
          <Switch
            id="showSources"
            checked={switchFields.showSources}
            onChange={() => setswitchFieldsHandler("showSources")}
          />
        </div>
        <hr />
        <LabelBox heading title="Data usage preference" />
        <p className="sub-description">
          Please select one of the following option for how you want your
          uploaded media/data to be used:
        </p>
        <div
          className={clsx("used-model-dropdown", usedMediaPreview && "active")}
        >
          <button
            className="selected-option"
            onClick={() => setusedMediaPreview(!usedMediaPreview)}
          >
            <Radio checked />
            <h4>{selectedUsedMedia.title}</h4>
            <p>{selectedUsedMedia.description}</p>
            <span className="toggle-btn">
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </button>
          <div className="dropdown-options">
            {USED_MEDIA_OPTIONS.filter(
              (x) => x.value !== selectedUsedMedia.value
            ).map((item) => (
              <button
                key={item.value}
                onClick={() => selectUsedModelHandler(item)}
              >
                <Radio />
                <span className="detail">
                  <span>{item.title}</span>
                  <span>{item.description}</span>
                </span>
              </button>
            ))}
          </div>
        </div>
        <hr />
        {selectedUsedMedia.value === USED_MEDIA_OPTIONS[2].value && (
          <>
            <LabelBox
              title="Custom search prompt"
              name="customSearchPrompt"
              message={
                <>
                  Custom search mode let you personalize how the AI responds by
                  specifying your preferences or providing context. This helps
                  the AI tailor its behavior, making responses more relevant and
                  aligned with your specific needs, improving overall efficiency
                  and satisfaction.
                  <br />
                  <br /> <span className="bold">Important:</span> “Knowledge
                  Base: &#123;data&#125; Question: &#123;query&#125;” must be at
                  the end of the custom search mode for it to pass validation.
                </>
              }
            />
            <Form.Item
              name="searchPrompt"
              rules={[
                ...inputFieldRule({
                  name: "Custom search prompt",
                  isWhiteSpace: false,
                }),
                {
                  pattern:
                    "([\\s\\S]*{data}[\\s\\S]*{query}[\\s\\S]*)|([\\s\\S]*{query}[\\s\\S]*{data}[\\s\\S]*)",
                  message: "Please enter both {data} and {query} placeholders",
                },
              ]}
            >
              <Input.TextArea
                rows={3}
                id="customSearchPrompt"
                onChange={fieldChangeHandler}
              />
            </Form.Item>
            <hr />
          </>
        )}
        {/* ENABLE ADVANCED SEARCH */}
        {/* <div className="label-box"> */}
        <LabelBox name="advancedOptions" title="Advanced search" />
        {/* <Switch
            id="enableMedia"
            checked={switchFields.advancedOptions}
            onChange={() => setswitchFieldsHandler("advancedOptions")}
          />
        </div> */}
        {/* {switchFields.advancedOptions && (
          <> */}
        <p className="sub-description">
          Advanced search allows you powerful features to interact with your
          files in an OpenSearch vector database. In most cases, the default
          values will provide reliable and effective results.
        </p>
        <LabelBox
          title="Retrieval type"
          name="retrievalType"
          message="Select how information is retrieved: 'Chunk' retrieves specific sections, 'Neighbor' retrieves related content, and 'Document' retrieves the entire document for context."
        />
        <Form.Item
          name="retrievalType"
          rules={inputFieldRule({
            name: "Retrieval type",
            isWhiteSpace: false,
            isRequired: false,
          })}
        >
          <Select
            showSearch
            id="retrievalType"
            onChange={fieldChangeHandler}
            getPopupContainer={(trigger) => trigger.parentNode}
            options={MEDIA_RETRIEVAL_OPTIONS}
            placeholder="Select retrieval type"
          />
        </Form.Item>
        {/* <hr /> */}
        <LabelBox
          heading
          title="Top K"
          message="Set the number of top results the AI retrieves or considers. A higher 'K' value means more results are evaluated, which can improve accuracy but may slow down response time."
        />
        <p className="sub-description">
          Set the number of chunks or documents you want to retrieve here. Keep
          in mind, a higher number may slow response time and potentially reduce
          quality.
        </p>
        <Form.Item
          id="topK"
          name="topK"
          rules={inputFieldRule({
            name: "Temperature",
            isWhiteSpace: false,
          })}
        >
          <Slider
            className="temperature-slider"
            onChange={fieldChangeHandler}
            marks={TOPK_MARKS}
            tooltip={{
              open: true,
              placement: "top",
              getPopupContainer: (triggerNode) => triggerNode.parentNode,
              formatter: topKSliderformatter,
            }}
          />
        </Form.Item>
        {/* <hr /> */}
        <LabelBox heading title="Expressions" />
        <p className="sub-description">
          Define a custom expression to filter the results.{" "}
          <span className="bold">Example: </span> "metadata1 ==
          'corresponding_filter' && metadata2 == 'corresponding_filter'"
        </p>
        <Form.Item
          name="expressions"
          rules={inputFieldRule({
            name: "Expression",
            isRequired: false,
          })}
        >
          <Input.TextArea rows={4} onChange={fieldChangeHandler} />
        </Form.Item>
        {/* </>
        )} */}
      </Form>
      <FormActions
        applyLoader={isLoading}
        resetPreview={resetPreview}
        applyPreview={applyPreview}
        resetHandler={resetHandler}
        applyHandler={applyHandler}
      />
    </AccordionBox>
  );
};

export default KnowledgeBaseAccordion;
