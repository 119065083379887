import React, { useState } from "react";
import { LabelBox } from "..";
import { getUserAsurite, toastAlert } from "../../../../../../utils";
import { ALERT_TYPES } from "../../../../../../constants";
import { TagInput } from "../../../../../../components";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const WebInterface = ({ data, viewers, setViewers, onChange }) => {
  // STATES
  const [isCopied, setCopied] = useState(false);

  // REDUX DATA
  const collaborators = useSelector(({ project }) => project.projectEditors);

  // CONST VALS
  const userAsurite = getUserAsurite();

  // HELPERS
  const validateField = (val, isCreate) => {
    if (!val) {
      toastAlert("Viewer cannot be empty", ALERT_TYPES.ERROR);
      return false;
    }
    if (isCreate && viewers.includes(val)) {
      toastAlert("Viewer already exists", ALERT_TYPES.ERROR);
      return false;
    }
    if (val === userAsurite) {
      toastAlert("You can't add yourself", ALERT_TYPES.ERROR);
      return false;
    }
    if (collaborators.includes(val)) {
      toastAlert("User already added as collaborator", ALERT_TYPES.ERROR);
      return false;
    }
    return true;
  };
  // HANDLERS
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const changeHandler = (viewers) => {
    onChange();
    setViewers(viewers);
  };

  return (
    <>
      <p className="sub-description">
        By default, projects are private unless you add viewers using their
        ASURITE IDs.
      </p>
      {/* PROJECT URL */}
      <div className="project-url-box">
        <LabelBox heading title="App URL" />
        <div className="project-url">
          <a
            className="url"
            target="_blank"
            rel="noreferrer"
            href={data?.publicUrl}
          >
            {data.publicUrl}
          </a>
          <CopyToClipboard text={data.publicUrl} onCopy={handleCopy}>
            <button type="button" className={clsx(isCopied && "active")}>
              <FontAwesomeIcon icon={isCopied ? faCircleCheck : faCopy} />
            </button>
          </CopyToClipboard>
        </div>
      </div>
      {/* PROJECT VIEWERS */}
      <LabelBox
        title="Viewers"
        message="Enter the ASURITE ID of users with view-only access to this project. Viewers can only access the public interface."
      />
      <TagInput
        editable
        tags={viewers}
        validator={validateField}
        onChange={changeHandler}
        className="viewers-input"
        placeholder="Add viewers"
      />
    </>
  );
};

export default WebInterface;
