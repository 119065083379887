import React, { useEffect, useState } from "react";
import BasicFields from "./basic-fields";
import { useSelector } from "react-redux";
import { Form, Switch } from "antd";
import {
  addUserInProjectRequest,
  editProjectRequest,
  getProjectUsersRequest,
  removeUserFromProjectRequest,
} from "../../../../../../redux/slicers/project";
import { CustomDispatch } from "../../../../../../helpers";
import { manipulateProjectBasicDetailPayload } from "../../../../../../data-manipulator/project";
import FormActions from "../form-actions";
import KnowledgeBase from "./knowledge-base";
import LabelBox from "../label-box";
import WebInterface from "./web-interface";
import { MODEL_OPTIONS } from "../../../../../../constants";
import "./styles.scss";

const BasicTab = ({ knowledgeBaseHandler }) => {
  // STATES
  const [webInterface, setwebInterface] = useState(false);
  const [applyPreview, setapplyPreview] = useState(false);
  const [viewers, setViewers] = useState([]);
  // TEMP WORK
  const [switchFields, setswitchFields] = useState({
    time: false,
    date: false,
    caching: false,
    advanced: false,
    enableSearch: false,
  });

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const projectViewers = useSelector(({ project }) => project.projectViewers);

  // CUSTOM DISPATCH
  const [getProjectUsers] = CustomDispatch(getProjectUsersRequest);
  const [editProject, editLoader] = CustomDispatch(editProjectRequest);
  const [addUser, addLoader] = CustomDispatch(addUserInProjectRequest);
  const [removeUser, removeLoader] = CustomDispatch(
    removeUserFromProjectRequest
  );

  // CONST VALS
  const [form] = Form.useForm();
  const isLoading = editLoader || addLoader || removeLoader;

  // HELPERS
  const setFieldsValuesHelper = () => {
    form.setFieldsValue(data);
    setwebInterface(data.webInterface);
    // TEMP WORK
    setswitchFields({
      time: data.time,
      date: data.date,
      caching: data.caching,
      advanced: data.advancedOptions,
      enableSearch: data.enableSearch,
    });
  };
  const getProjectUsersHelper = () => {
    const payload = {
      resource: "access",
      method: "describe",
      details: {
        project_id: data.id,
      },
    };
    getProjectUsers({
      payload,
    });
  };
  const addViewersHelper = () => {
    const users = viewers
      .filter((x) => !projectViewers.includes(x))
      .map((x) => ({ user_id: x, role: "viewer" }));
    if (users.length < 1) return;
    const payload = {
      resource: "access",
      method: "add",
      details: {
        project_id: data?.id,
        users,
      },
    };
    addUser({
      payload,
    });
  };
  const removeViewersHelper = () => {
    const users = projectViewers
      .filter((x) => !viewers.includes(x))
      .map((x) => ({ user_id: x, role: "viewer" }));

    if (users.length < 1) return;
    const payload = {
      resource: "access",
      method: "remove",
      details: {
        project_id: data?.id,
        users,
      },
    };
    removeUser({
      payload,
    });
  };
  const editProjectHelper = (values) => {
    const model = MODEL_OPTIONS.find((item) => item.value === values.model);
    const details = {
      ...values,
      webInterface,
      ...switchFields,
      id: data.id,
      provider: model.provider,
    };
    const payload = {
      resource: "project",
      method: "update",
      details: manipulateProjectBasicDetailPayload(details),
    };
    editProject({
      payload,
      callback: (status) => setapplyPreview(!status),
    });
  };

  // HANDLERS
  const fieldChangeHandler = (status = true) => {
    setapplyPreview(status);
  };
  const webInterfaceHandler = () => {
    fieldChangeHandler();
    setwebInterface(!webInterface);
  };

  // TEMP WORK
  const switchFieldsHandler = (name) => {
    fieldChangeHandler();
    setswitchFields({ ...switchFields, [name]: !switchFields[name] });
  };

  const applyHandler = () => {
    form
      .validateFields()
      .then((values) => {
        fieldChangeHandler(false);
        editProjectHelper(values);
        addViewersHelper();
        removeViewersHelper();
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => getProjectUsersHelper(), []);
  useEffect(() => {
    if (data) setFieldsValuesHelper();
  }, [data]);
  useEffect(() => {
    if (projectViewers && projectViewers.length) {
      setViewers(projectViewers);
    }
  }, [projectViewers]);

  return (
    <Form
      form={form}
      className="basic-tab customize-form"
      onFieldsChange={() => fieldChangeHandler()}
    >
      <BasicFields
        // TEMP WORK
        switchFields={switchFields}
        switchFieldsHandler={switchFieldsHandler}
      />
      <hr />
      <KnowledgeBase
        switchFields={switchFields}
        switchFieldsHandler={switchFieldsHandler}
        knowledgeBaseHandler={knowledgeBaseHandler}
      />
      <hr />
      <div className="label-box">
        <LabelBox title="Enable web app" name="webInterface" />
        <Switch
          id="webInterface"
          checked={webInterface}
          onChange={webInterfaceHandler}
        />
      </div>
      {webInterface && (
        <WebInterface
          data={data}
          viewers={viewers}
          setViewers={setViewers}
          onChange={fieldChangeHandler}
        />
      )}
      <FormActions
        applyLoader={isLoading}
        applyPreview={applyPreview}
        applyHandler={applyHandler}
      />
    </Form>
  );
};

export default BasicTab;
