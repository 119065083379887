import React, { useState, useRef } from "react";
import Tag from "./tag.jsx";
import clsx from "clsx";
import "./styles.scss";

const TagInput = ({
  tags,
  onChange,
  className,
  placeholder,
  maxTags,
  validator,
  editable,
  readOnly,
  removeOnBackspace,
}) => {
  // STATES
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  // HANDLERS
  const onInputChange = (e) => {
    setInput(e.target.value);
  };
  const onInputKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      if (input === "") return;
      const valid = validator !== undefined ? validator(input, true) : true;
      if (!valid) return;
      addTag(input);
    }
    // On backspace or delete
    else if (removeOnBackspace && (e.keyCode === 8 || e.keyCode === 46)) {
      if (input !== "") return;
      removeTag(tags.length - 1);
    }
  };
  const addTag = (value) => {
    const newTags = [...tags];
    newTags.push(value);
    setInput("");
    onChange(newTags);
  };
  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    onChange(newTags);
  };
  const updateTag = (i, value) => {
    const newTags = [...tags];
    newTags[i] = value;
    onChange(newTags);
  };

  // CONST VALS
  const maxTagsReached = maxTags !== undefined ? tags.length >= maxTags : false;
  const isEditable = readOnly ? false : editable || false;
  const showInput = !maxTagsReached;

  return (
    <div className={clsx("tag-input", readOnly && "disabled", className)}>
      {tags.map((tag, i) => (
        <Tag
          key={i}
          value={tag}
          index={i}
          editable={isEditable}
          readOnly={readOnly || false}
          inputRef={inputRef}
          update={updateTag}
          remove={removeTag}
          validator={validator}
          removeOnBackspace={removeOnBackspace}
        />
      ))}
      {showInput && (
        <input
          ref={inputRef}
          value={input}
          disabled={!isEditable}
          className="tag-input__input"
          placeholder={placeholder || "Type and press enter"}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
        />
      )}
    </div>
  );
};

export default TagInput;
