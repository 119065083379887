import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
import { Button, Modal } from "../../../../../components";
import { inputFieldRule } from "../../../../../utils";
import { CustomDispatch } from "../../../../../helpers";
import { addUserInGroupRequest } from "../../../../../redux/slicers/groups";
import { ROLE_OPTIONS } from "../../../../../constants";
import "./styles.scss";

const AddUser = ({
  isEdit,
  data,
  preview,
  previewHandler,
  successHandler = () => {},
}) => {
  // CUSTOM DISPATCH
  const [addUser, isLoading] = CustomDispatch(addUserInGroupRequest);

  // REDUX DATA
  const { selectedGroup } = useSelector(({ groups }) => groups);

  // CONST VALS
  const [form] = Form.useForm();

  // HELPERS
  const requestHelper = (payload) => {
    addUser({
      payload,
      isEdit,
      success() {
        successHandler();
        previewHandler();
        form.resetFields();
      },
    });
  };

  // HANDLERS
  const closeHandler = () => {
    form.resetFields();
    previewHandler();
  };
  const submitHandler = ({ id = data.id, role }) => {
    const payload = {
      resource: "access",
      method: "add",
      details: {
        group_id: selectedGroup.id,
        users: [
          {
            user_id: id,
            role: role,
          },
        ],
      },
    };
    requestHelper(payload);
  };

  // HOOKS
  useEffect(() => {
    if (preview && isEdit) form.setFieldsValue(data);
  }, [preview]);

  return (
    <Modal
      width={550}
      title={isEdit ? "Edit User Role" : "Add User"}
      className="add-user"
      open={preview}
      openHandler={closeHandler}>
      <Form form={form} onFinish={submitHandler} className="customize-form">
        {!isEdit && (
          <Form.Item
            name="id"
            rules={inputFieldRule({
              name: "Asurite ID",
              isMax: true,
              max: 60,
            })}>
            <Input type="text" placeholder="User asurite ID" />
          </Form.Item>
        )}
        <Form.Item
          name="role"
          rules={inputFieldRule({
            name: "Role",
            isWhiteSpace: false,
          })}>
          <Select
            options={ROLE_OPTIONS}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select user role"
          />
        </Form.Item>
        <div className="bottom-wrapper">
          <Button invertedTheme isLoading={isLoading}>
            {isEdit ? "Update" : "Create"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddUser;
