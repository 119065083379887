import React from "react";
import "./styles.scss";

const PageTitle = ({
  title = "",
  subtitle = "",
  description = "",
  className = "",
  children,
}) => {
  return (
    <div className={`page-title ${className}`}>
      <div className="top-content">
        <div className="title-box">
          <h1>{title}</h1>
          <h6>{subtitle}</h6>
        </div>
        {children}
      </div>
      {description.length >= 1 && <p className="description">{description}</p>}
    </div>
  );
};

export default PageTitle;
