import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const AccordionBox = ({
  className = "",
  preview = false,
  title = "",
  children,
}) => {
  // STATES
  const [isVisible, setVisible] = useState(preview);

  // HANDLERS
  const setVisibleHandler = () => {
    setVisible(!isVisible);
  };

  return (
    <div className={clsx("accordion-box", className, isVisible && "active")}>
      <button className="header" onClick={setVisibleHandler}>
        <span className="title">{title}</span>
        <FontAwesomeIcon icon={faChevronDown} className="toggle" />
      </button>
      <div className="content">{children}</div>
    </div>
  );
};

export default AccordionBox;
