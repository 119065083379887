import React, { useState, useEffect } from "react";
import { Slider as AntdSlider } from "antd";

const Slider = ({
  defaultValue = 1,
  val,
  className,
  onChange = () => {},
  ...rest
}) => {
  // STATES
  const [value, setValue] = useState(defaultValue);

  // HANDLERS
  const setValueHandler = (val) => {
    setValue(val);
    onChange(val);
  };

  // HOOKS
  useEffect(() => {
    setValue(val);
  }, [val]);
  return (
    <AntdSlider
      className={`custom-slider ${className}`}
      value={value}
      onChange={setValueHandler}
      {...rest}
    />
  );
};

export default Slider;
