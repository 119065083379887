import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { LabelBox } from "../../";
import {
  getUserAsurite,
  inputFieldRule,
  toastAlert,
} from "../../../../../../../utils";
import { useSelector } from "react-redux";
import { CustomDispatch } from "../../../../../../../helpers";
import { getGroupDetailRequest } from "../../../../../../../redux/slicers/groups";
import {
  ALERT_TYPES,
  TOAST_MESSAGES,
  VISIBILITY_OPTIONS,
} from "../../../../../../../constants";

const AccessControl = ({ form, collaborators, setCollaborators }) => {
  // STATES
  const [users, setUsers] = useState([]);
  const [groupPreview, setgroupPreview] = useState(false);
  const [collabPreview, setcollabPreview] = useState(false);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const groups = useSelector(({ groups }) => groups.groups);
  const projectEditors = useSelector(({ project }) => project.projectEditors);
  const projectViewers = useSelector(({ project }) => project.projectViewers);

  // CONST VALS
  const userAsurite = getUserAsurite();
  const isShared = data?.visibility === VISIBILITY_OPTIONS[1].value;

  // CUSTOM DISPATCH
  const [getUsers, usersLoader] = CustomDispatch(getGroupDetailRequest);

  // HELPERS
  const filterCollaborators = (input, option) => {
    return (option?.children ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const getUsersHelper = (group) => {
    const payload = {
      resource: "group",
      method: "describe",
      details: {
        group_id: group,
      },
    };
    getUsers({
      onlyResponse: true,
      payload,
      success: (res) => {
        setUsers(res.users.filter((x) => x.id !== userAsurite));
      },
    });
  };
  const setCollabHelper = () => {
    setCollaborators(isShared ? projectEditors : []);
    form.setFieldsValue({ editors: isShared ? projectEditors : undefined });
  };

  // HANDLERS
  const visbilityHandler = (value) => {
    form.setFieldsValue({
      group: undefined,
      editors: undefined,
    });
    const isShared = value === VISIBILITY_OPTIONS[1].value;
    setgroupPreview(isShared);
    setcollabPreview(false);
  };
  const groupHandler = (value) => {
    setCollaborators([]);
    form.setFieldsValue({ editors: undefined });
    getUsersHelper(value);
    setcollabPreview(true);
  };

  const setCollaboratorsHandler = (data) => {
    const isUserExist = data.some((collab) => projectViewers.includes(collab));
    if (isUserExist) {
      toastAlert(TOAST_MESSAGES.USER_EXSIST_AS_VIEWER, ALERT_TYPES.ERROR);
      form.setFieldsValue({ editors: collaborators });
      return;
    }
    setCollaborators(data);
  };

  // HOOKS
  useEffect(() => setCollabHelper(), [projectEditors, isShared]);
  useEffect(() => {
    const isShared = data.visibility === VISIBILITY_OPTIONS[1].value;
    setgroupPreview(isShared);
    if (data?.group && isShared) {
      setcollabPreview(isShared);
      getUsersHelper(data.group);
    }
  }, [data]);

  return (
    <>
      <p className="sub-description">
        Restricts user access to specific features and data within your
        application based on their roles and permissions.
      </p>
      <LabelBox
        title="Visibility"
        name="visibility"
        message="Choose the privacy level for this project."
      />
      <Form.Item
        name="visibility"
        rules={inputFieldRule({
          name: "Visibility",
          isWhiteSpace: false,
        })}
      >
        <Select
          id="visibility"
          onChange={visbilityHandler}
          options={VISIBILITY_OPTIONS}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select visibility"
        />
      </Form.Item>
      {/* GROUP */}
      <LabelBox
        title="Group"
        name="group"
        message="Select the group with which the project is associated. Users from the group can be assigned as collaborators to help mange, build and edit this project."
      />
      <Form.Item
        name="group"
        rules={inputFieldRule({
          name: "Group",
          isRequired: groupPreview,
          isWhiteSpace: false,
        })}
      >
        <Select
          id="group"
          disabled={!groupPreview}
          onChange={groupHandler}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select group"
        >
          {groups.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <LabelBox
        title="Collaborators"
        name="collaborator"
        message="Select the users associated with the group above to work as collaborators to help mange, build and edit this project. "
      />
      <Form.Item
        name="editors"
        rules={inputFieldRule({
          name: "Collaborator",
          isRequired: false,
          isWhiteSpace: false,
        })}
      >
        <Select
          id="collaborator"
          showSearch
          mode="multiple"
          loading={usersLoader}
          disabled={!collabPreview}
          onChange={setCollaboratorsHandler}
          value={collaborators}
          optionLabelProp="label"
          filterOption={filterCollaborators}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select collaborator"
        >
          {users.map((data, index) => (
            <Select.Option
              value={data.id}
              label={data.name !== "" ? data.name : data.id}
              key={index}
            >
              {data.name ?? data.id}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default AccessControl;
