import React from "react";
import { useSelector } from "react-redux";
import { Image } from "antd";
// import { Dropdown } from "../../../../components";
import {
  DASHBOARD_ROUTE,
  DEFAULT_USER_THUMB,
  GROUPS_ROUTE,
  LEARNING_ROUTE,
  MARKETPLACE_ROUTE,
  PROJECTS_ROUTE,
} from "../../../../constants";
import clsx from "clsx";
import {
  faPenRuler,
  faBookOpen,
  faPeopleGroup,
  faSearch,
  faHome,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

const Sidebar = ({ preview, createProjectHandler, previewHandler }) => {
  // REDUX DATA
  const { data: userdata, groupAccess } = useSelector(({ user }) => user);

  // CONST VALS
  const currentRoute = window.location.pathname;
  // const createDropdownOptions = [
  //   { label: "project", value: "project", onClick: createProjectHandler },
  //   // { label: "Template", value: "template" },
  // ];
  const navigationTabs = [
    {
      icon: faHome,
      label: "Dashboard",
      route: DASHBOARD_ROUTE,
    },
    {
      icon: faPeopleGroup,
      label: "Groups",
      route: GROUPS_ROUTE,
      onlyAdmin: true,
    },
    {
      icon: faPenRuler,
      label: "MyAI Projects",
      route: PROJECTS_ROUTE,
    },
    {
      icon: faSearch,
      label: "MyAI Marketplace",
      route: MARKETPLACE_ROUTE,
    },
    {
      icon: faBookOpen,
      label: "Learning",
      route: LEARNING_ROUTE,
    },
  ];

  return (
    <nav
      role="navigation"
      className={clsx("dashboard-sidebar", preview && "active")}
    >
      <button className="sidebar-toggle" onClick={previewHandler}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <div className="mobile-box">
        {/* PROFILE BOX */}
        <div className="user-profile">
          <span className="thumb">
            <Image
              preview={false}
              src={userdata?.profilePic}
              fallback={DEFAULT_USER_THUMB}
              alt=""
            />
          </span>
          <span className="username">{userdata.username}</span>
        </div>
        {/* CREATE NEW */}
        {/* <Dropdown
          className={`create-dropdown`}
          placeholder="Create new"
          options={createDropdownOptions}
          optionBox={(option) => option.label}
          selectHandler={({ onClick }) => onClick()}
        /> */}
      </div>
      {/* NAV ITEMS */}
      <ul className="nav-menu">
        {navigationTabs.map((tab, index) => {
          const groupTabPreview = tab.onlyAdmin && !groupAccess;
          if (groupTabPreview) return null;
          return (
            <li key={index}>
              <Link
                to={tab.route}
                className={clsx(currentRoute === tab.route && "active")}
                // onClick={previewHandler}
              >
                <FontAwesomeIcon icon={tab.icon} className="thumb" />
                <span className="title">{tab.label}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
