import React from "react";
import "./styles.scss";
import clsx from "clsx";

const Backdrop = ({ zIndex = 99, isMobile, opacity = 0.1, ...rest }) => {
  return (
    <div
      className={clsx("backdrop", isMobile && "mobile")}
      style={{ zIndex: zIndex, opacity: opacity }}
      {...rest}
    />
  );
};

export default Backdrop;
