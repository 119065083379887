import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { AccordionBox, FormActions, LabelBox } from "../../";
import { useSelector } from "react-redux";
import {
  addUserInProjectRequest,
  editProjectRequest,
  removeUserFromProjectRequest,
} from "../../../../../../../redux/slicers/project";
import { CustomDispatch } from "../../../../../../../helpers";
import { manipulateProjectInterfacePayload } from "../../../../../../../data-manipulator/project";
// import BasicDetails from "./basic-details";
import AccessControl from "./access-control";
import { getGroupsRequest } from "../../../../../../../redux/slicers/groups";
// import ApiAccess from "./api-access";
import UiBuilder from "./ui-builder";
import {
  PROJECT_ROLES,
  VISIBILITY_OPTIONS,
} from "../../../../../../../constants";
import "./styles.scss";

const InterfaceAccordion = ({ changeTab }) => {
  // STATES
  const [switchFields, setswitchFields] = useState({
    // accessControl: false,
    enableApiAccess: false,
    // uiBuilder: false,
    screenImage: false,
  });
  const [collaborators, setCollaborators] = useState([]);
  const [resetPreview, setResetPreview] = useState(false);
  const [applyPreview, setapplyPreview] = useState(false);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const projectEditors = useSelector(({ project }) => project.projectEditors);

  // CUSTOM DISPATCH
  const [getGroups] = CustomDispatch(getGroupsRequest);
  const [editProject, editLoader] = CustomDispatch(editProjectRequest);
  const [addUser, addLoader] = CustomDispatch(addUserInProjectRequest);
  const [removeUser, removeLoader] = CustomDispatch(
    removeUserFromProjectRequest
  );

  // CONST VALS
  const [form] = Form.useForm();
  const isShared = data.visibility === VISIBILITY_OPTIONS[1].value;
  const isLoading = editLoader || addLoader || removeLoader;
  const isOwner = data.role === PROJECT_ROLES.OWNER;

  // HELPERS
  const setFieldsValuesHelper = () => {
    form.setFieldsValue({ ...data, group: isShared ? data.group : undefined });
    setswitchFields(data);
  };
  const getGroupsHelper = () => {
    const payload = {
      resource: "group",
      method: "list",
    };
    getGroups({
      payload,
    });
  };
  const addCollaboratorsHelper = (isPrivate) => {
    if (isPrivate) return;
    const users = collaborators
      .filter((x) => !projectEditors.includes(x))
      .map((x) => ({ user_id: x, role: "editor" }));
    if (users.length < 1) return;
    const payload = {
      resource: "access",
      method: "add",
      details: {
        project_id: data?.id,
        users,
      },
    };
    addUser({
      payload,
    });
  };
  const removeCollaboratorsHelper = (isPrivate) => {
    const removedCollabs = projectEditors
      .filter((x) => !collaborators.includes(x))
      .map((x) => ({ user_id: x, role: "editor" }));
    const allCollabs = projectEditors.map((x) => ({
      user_id: x,
      role: "editor",
    }));
    const users = isPrivate ? allCollabs : removedCollabs;
    if (users.length < 1) return;
    const payload = {
      resource: "access",
      method: "remove",
      details: {
        project_id: data?.id,
        users,
      },
    };
    removeUser({
      payload,
    });
  };
  const editProjectHelper = (values) => {
    const details = { ...data, ...switchFields, ...values };
    const payload = {
      resource: "project",
      method: "update",
      details: manipulateProjectInterfacePayload(details),
    };
    editProject({
      payload,
      callback: (status) => {
        fieldChangeHandler(!status);
      },
    });
  };

  // HANDLERS
  const fieldChangeHandler = (status = true) => {
    setResetPreview(status);
    setapplyPreview(status);
  };
  // const switchFieldsHandler = (name) => {
  //   fieldChangeHandler();
  //   setswitchFields({ ...switchFields, [name]: !switchFields[name] });
  // };
  const resetHandler = () => {
    setFieldsValuesHelper();
    setResetPreview(false);
    setapplyPreview(false);
  };
  const applyHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const isPrivate = values.visibility === VISIBILITY_OPTIONS[0].value;
        editProjectHelper(values);
        addCollaboratorsHelper(isPrivate);
        removeCollaboratorsHelper(isPrivate);
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => getGroupsHelper(), []);
  useEffect(() => {
    if (data) setFieldsValuesHelper();
  }, [data]);

  return (
    <AccordionBox className="interface-accordion" title="Interface">
      {/* INTERFACE BASIC SETTING */}
      {/* <BasicDetails changeTab={changeTab} />
      <hr /> */}
      <Form
        form={form}
        className="ui-accordion customize-form"
        onFieldsChange={() => fieldChangeHandler()}
      >
        {/* PROJECT ACCESS CONTROL */}
        {isOwner && (
          <>
            {/* <div className="label-box"> */}
            <LabelBox title="Access control" name="accessControl" />
            {/* <Switch
            id="accessControl"
            checked={switchFields.accessControl}
            onChange={() => switchFieldsHandler("accessControl")}
          />
        </div> */}
            {/* {switchFields.accessControl && ( */}
            <AccessControl
              form={form}
              collaborators={collaborators}
              setCollaborators={setCollaborators}
            />
            {/* )} */}
            <hr />
          </>
        )}
        {/* PROJECT API ACCESS */}
        {/* <div className="label-box">
          <LabelBox title="Enable API access" name="enableApiAccess" />
          <Switch
            id="enableApiAccess"
            checked={switchFields.enableApiAccess}
            onChange={() => switchFieldsHandler("enableApiAccess")}
          />
        </div>
        {switchFields.enableApiAccess && <ApiAccess />}
        <hr /> */}
        {/* PROJECT UI BUILDER */}
        {/* <div className="label-box"> */}
        <LabelBox title="UI Builder" name="uiBuilder" />
        {/* <Switch
            id="uiBuilder"
            checked={switchFields.uiBuilder}
            onChange={() => switchFieldsHandler("uiBuilder")}
          />
        </div> */}
        {/* {switchFields.uiBuilder && */}
        <UiBuilder />
        {/* } */}
        <FormActions
          applyLoader={isLoading}
          resetPreview={resetPreview}
          applyPreview={applyPreview}
          resetHandler={resetHandler}
          applyHandler={applyHandler}
        />
      </Form>
    </AccordionBox>
  );
};

export default InterfaceAccordion;
