import { call, fork, put, take } from "redux-saga/effects";
import {
  callRequest,
  GET_USER_ACCESS,
  GET_USER_DETAIL,
} from "../../config/web-service";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  getUserAccessRequest,
  getUserAccessSuccess,
  getUserDetailsRequest,
  getUserDetailsSuccess,
} from "../slicers/user";
import { manipulateUserData } from "../../data-manipulator/user";

// GET LOGGED IN USER DETAIL
function* getUserDetail() {
  while (true) {
    const {
      payload: { queryParams, loggedinUser, responseCallback },
    } = yield take(getUserDetailsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_USER_DETAIL,
        queryParams,
      });
      responseCallback?.(response?.status, response.data);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);

      if (response.status && loggedinUser)
        yield put(getUserDetailsSuccess(manipulateUserData(response.data)));
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GET LOGGED IN USER DETAIL
function* getUserAccess() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getUserAccessRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_USER_ACCESS,
        payload,
      });
      responseCallback?.(response?.status, response.data);
      if (response.status) yield put(getUserAccessSuccess(response.data));
      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}
export default function* root() {
  yield fork(getUserDetail);
  yield fork(getUserAccess);
}
