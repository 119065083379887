import { Button } from "../../../../components";
import { APIDOC_ROUTE } from "../../../../constants";

function Banner({ imgSrc, title, description, btnText }) {
  return (
    <div className="banner-wrapper">
      <div className="image-box">
        <img src={imgSrc} alt="ASU Banner" />
      </div>
      <div className="detail">
        <h2>{title}</h2>
        <p>{description}</p>
        <Button
          isLink
          link={APIDOC_ROUTE}
          className="cta bold"
          invertedTheme={true}
          type="pill"
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
}

export default Banner;
