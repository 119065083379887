// @flow
import { createSlice } from "@reduxjs/toolkit";

const GeneralReducer = createSlice({
  name: "general",
  initialState: {
    media: [],
  },
  reducers: {
    // GENERATE UPLOAD URL
    generateUploadURLRequest() {},

    // UPLOAD MEDIA
    uploadMediaRequest() {},
  },
});

export const { generateUploadURLRequest, uploadMediaRequest } =
  GeneralReducer.actions;

export default GeneralReducer.reducer;
