import React from "react";
import { Button } from "../../../";
import { useNavigate } from "react-router-dom";
import { PROJECT_ROUTE } from "../../../../constants";

const CongratulationStep = ({ data = {}, previewHandler = () => {} }) => {
  // CONST VALS
  const navigate = useNavigate();

  // HANDLERS
  const navigateToProject = () => {
    navigate(PROJECT_ROUTE.replace(":projectslug", data.id));
    previewHandler();
  };

  return (
    <div className="congratulation-step">
      <div className="content-wrapper">
        <h2>Congratulations</h2>
        <p>Your project has been successfully created</p>
      </div>
      <div className="bottom-wrapper">
        <Button
          invertedTheme
          className="next-step-btn"
          onClick={navigateToProject}>
          Take me to the project
        </Button>
      </div>
    </div>
  );
};

export default CongratulationStep;
