import React, { useMemo } from "react";
import {
  Projects,
  // Resources
} from "./components";
import "./styles.scss";
import Banner from "./components/banner";
import { Images } from "../../../theme";

const Dashboard = () => {
  // STATES

  // CUSTOM COMPONENT
  const projectsComp = useMemo(() => {
    return <Projects />;
  }, []);

  return (
    <div className="dashboard">
      <Banner
        imgSrc={Images.ASUBanner}
        title="Welcome To MyAI Builder"
        description="The intuitive platform designed to empower you to create sophisticated AI-powered applications."
        btnText="View api docs"
      />
      {projectsComp}
    </div>
  );
};

export default Dashboard;
