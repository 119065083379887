import React, { useState } from "react";
import {
  ConfirmationModal,
  Table,
  // TagsBox,
} from "../../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faEdit,
} from "@fortawesome/free-regular-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { Checkbox, Tooltip } from "antd";
import clsx from "clsx";
import AddUrlForm from "./add-form";

const ProjectUrlsListing = ({
  isLoading,
  urls = [],
  setUrls,
  deleteUrls,
  enableSavePreview,
  deleteLoader,
}) => {
  // STATES
  const [editUrlPreview, seteditUrlPreview] = useState(false);
  const [deleteUrlPreview, setDeleteUrlPreview] = useState(false);
  const [selectedData, setselectedData] = useState(null);

  // HANDLERS
  const editUrlPreviewHandler = (data) => {
    setselectedData(data);
    seteditUrlPreview(true);
  };
  const editUrlHandler = (data) => {
    seteditUrlPreview(false);
    enableSavePreview();
    const temp = [...urls];
    const index = temp.findIndex((item) => item.name === selectedData.name);
    temp[index] = data;
    setUrls(temp);
  };
  const deleteUrlPreviewHandler = (data) => {
    setDeleteUrlPreview(true);
    setselectedData(data);
  };

  const deleteUrlHandler = () => {
    deleteUrls(selectedData, () => {
      setDeleteUrlPreview(false);
    });
  };

  const selectAllHandler = () => {
    const temp = [...urls];
    const isAllSelected = urls.every((item) => item.selected);

    temp.forEach((item) => (item.selected = !isAllSelected));
    setUrls(temp);
  };
  const updateUrl = (key, name) => {
    if (key !== "selected") enableSavePreview();
    let temp = [...urls];
    const index = temp.findIndex((item) => item.name === name);
    temp[index][key] = !temp[index][key];
    if (!temp[index].isCreated) temp[index].isUpdated = true;
    setUrls(temp);
  };
  // const addTagHandler = (data, tag) => {
  //   enableSavePreview();
  //   setUrls((prevUrl) => {
  //     const urlCopy = [...prevUrl];
  //     const urlIndex = urlCopy.findIndex((item) => item.name === data.name);
  //     urlCopy[urlIndex].tags.push(tag);
  //     if (!urlCopy[urlIndex].isCreated) urlCopy[urlIndex].isUpdated = true;
  //     return urlCopy;
  //   });
  // };
  // const editTagHandler = (data, index, tag) => {
  //   enableSavePreview();
  //   const urlCopy = [...urls];
  //   const urlIndex = urlCopy.findIndex((item) => item.name === data.name);
  //   urlCopy[urlIndex].tags[index] = tag;
  //   if (!urlCopy[urlIndex].isCreated) urlCopy[urlIndex].isUpdated = true;
  //   setUrls(urlCopy);
  // };
  // const deleteTagHandler = (data, index) => {
  //   enableSavePreview();
  //   const urlCopy = [...urls];
  //   const urlIndex = urlCopy.findIndex((item) => item.name === data.name);
  //   urlCopy[urlIndex].tags.splice(index, 1);
  //   if (!urlCopy[urlIndex].isCreated) urlCopy[urlIndex].isUpdated = true;
  //   setUrls(urlCopy);
  // };

  // CONST VALS
  const isAllSelected = urls.every((item) => item.selected) && urls.length > 0;
  const columns = [
    {
      title: () => (
        <button onClick={selectAllHandler}>
          <Checkbox id={`url-selector-all`} checked={isAllSelected} />
        </button>
      ),
      dataIndex: "",
      fixed: "left",
      className: "select-cell customize-form",
      width: 25,
      render: (data) => (
        <button onClick={() => updateUrl("selected", data.name)}>
          <Checkbox id={`url-selector-${data.id}`} checked={data.selected} />
        </button>
      ),
    },
    {
      className: "delete-cell",
      fixed: "left",
      render: (data) => (
        <button
          className="delete-btn"
          onClick={() => editUrlPreviewHandler(data)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    {
      title: () => <h5>Name</h5>,
      dataIndex: "",
      fixed: "left",
      className: "title-cell customize-form",
      width: 240,
      render: (data) => (
        <button style={{ cursor: "auto" }}>
          <FontAwesomeIcon className="url-icon" icon={faGlobe} />
          <span>{data.name}</span>
        </button>
      ),
    },
    {
      title: () => <h5>Status</h5>,
      render: (data) => (
        <>
          <span className={clsx(data.isPending && "rotate-icon")}>
            <FontAwesomeIcon
              style={{ color: data.statusColor }}
              icon={data.statusIcon}
            />
          </span>
          <span className="status-label">{data.status}</span>
        </>
      ),
    },
    {
      title: () => <h5>Added on</h5>,
      dataIndex: "uploadDate",
      className: "small-cell",
    },
    // {
    //   title: () => (
    //     <h5>
    //       Tags
    //       <Tooltip title="Assign tags to files to enable easy search and filtering. Use relevant keywords to categorize files for quicker access and organization.">
    //         <FontAwesomeIcon icon={faQuestionCircle} />
    //       </Tooltip>
    //     </h5>
    //   ),
    //   className: "tags-cell",
    //   render: (data) => (
    //     <TagsBox
    //       tags={data.tags}
    //       addTag={(val) => addTagHandler(data, val)}
    //       editTag={(index, val) => editTagHandler(data, index, val)}
    //       deleteTag={(index) => deleteTagHandler(data, index)}
    //     />
    //   ),
    // },
    {
      title: () => <h5>Index level</h5>,
      dataIndex: "indexDepth",
    },
    {
      title: () => <h5>Include docs/PDFs</h5>,
      dataIndex: "docIndexing",
      render: (val) => (val ? "Enabled" : "Disabled"),
    },
    {
      title: () => <h5>Include or exclude urls</h5>,
      className: "filter-cell",
      children: [
        {
          title: () => (
            <h6>
              In GPT Interactions
              <Tooltip title="Include or exclude files from GPT interactions. The files included may be referenced in your GPT responses, but not all data is guaranteed to appear. Excluded files will be ignored">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </h6>
          ),
          className: "reference-cell",
          render: (data) => (
            <button
              onClick={() => updateUrl("referenced", data.name)}
              className={clsx(data.referenced && "active")}
            >
              <FontAwesomeIcon icon={data.referenced ? faEye : faEyeSlash} />
            </button>
          ),
        },
        // {
        //   title: () => (
        //     <h6>
        //       Expose as app filter
        //       <Tooltip title="Enable or disable to allow or prevent a file from being referenced in the end-user's front-end application. Enabled files may be used in the application, while disabled files will be ignored.">
        //         <FontAwesomeIcon icon={faQuestionCircle} />
        //       </Tooltip>
        //     </h6>
        //   ),
        //   className: "expose-cell",
        //   render: (data) => (
        //     <button
        //       onClick={() => updateUrl("exposed", data.name)}
        //       className={clsx(data.exposed && "active")}
        //     >
        //       <FontAwesomeIcon icon={data.exposed ? faEye : faEyeSlash} />
        //     </button>
        //   ),
        // },
      ],
    },
    {
      className: "delete-cell",
      render: (data) => (
        <button
          className="delete-btn"
          onClick={() => deleteUrlPreviewHandler(data)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      ),
    },
  ];

  return (
    <>
      <Table
        height="500px"
        className="urls-listing"
        borderedTable
        data={urls}
        columns={columns}
        isLoading={isLoading}
      />
      {/* EDIT URL MODAL */}
      <AddUrlForm
        isEdit
        data={selectedData}
        preview={editUrlPreview}
        submit={editUrlHandler}
        previewHandler={() => seteditUrlPreview(false)}
      />
      {/* DELETE URL MODAL */}
      <ConfirmationModal
        title="Delete Url"
        description="Are you sure you want to delete this url?"
        preview={deleteUrlPreview}
        isLoading={deleteLoader}
        previewHandler={() => setDeleteUrlPreview(false)}
        confirmBtnHandler={deleteUrlHandler}
      />
    </>
  );
};

export default ProjectUrlsListing;
