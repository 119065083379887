import React from "react";
import { toastAlert, validateFile } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { ALERT_TYPES } from "../../constants";
import "./styles.scss";

const ACCEPT_VALIDATION = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "text/plain": [".txt"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "text/html": [".html", ".htm"], 
};

const DropZone = ({
  uploadFile = () => {},
  fileValidation = ACCEPT_VALIDATION,
}) => {
  // HANDLERS
  const uploadFileHandler = (files) => {
    const validFiles = [];
    files.forEach((file) => {
      if (validateFile(file, false)) {
        validFiles.push(file);
      }
    });
    // for (let i = 0; i < files.length; i++) {
    //   const file = files[i];
    //   if (fileValidation(file)) {
    //     validFiles.push(file);
    //   }
    // }
    validFiles.length >= 1 && uploadFile(validFiles);
  };
  // CONST VALS
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    maxFiles: 30,
    accept: fileValidation,
    onDropAccepted: (file) => {
      uploadFileHandler(file);
    },
    onDropRejected: () => toastAlert("Invalid file type", ALERT_TYPES.ERROR),
  });
  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className={`dropzone-upload-box ${isDragActive ? "active" : ""}`}>
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faCloudArrowUp} className="thumb" />
      {isDragActive ? (
        <p>Drop the files here</p>
      ) : (
        <>
          <p>Drag a file here to upload or</p>
          <button onClick={open}>Browse files</button>
        </>
      )}
    </div>
  );
};

export default DropZone;
