import React, { useEffect, useState } from "react";
import { Button, Modal, Slider } from "../../../../../components";
import { Col, Form, Input, InputNumber, Row } from "antd";
import { inputFieldRule } from "../../../../../utils";
import { CustomDispatch } from "../../../../../helpers";
import {
  createGroupRequest,
  editGroupRequest,
} from "../../../../../redux/slicers/groups";
import "./styles.scss";

const CreateGroup = ({ isEdit, data, preview, previewHandler }) => {
  // STATES
  const [tpm, setTpm] = useState(1);

  // CUSTOM DISPATCH
  const [createGroup, createLoader] = CustomDispatch(createGroupRequest);
  const [updateGroup, updateLoader] = CustomDispatch(editGroupRequest);

  // CONST VALS
  const [form] = Form.useForm();
  const isLoading = createLoader || updateLoader;
  const marks = {
    0: "1k",
    100: "300k",
  };

  // HELPERS
  const requestHelper = (request, payload) => {
    request({
      payload,
      success() {
        closeHandler();
      },
    });
  };

  // HANDLERS
  const closeHandler = () => {
    form.resetFields();
    setTpm(1);
    previewHandler();
  };
  const setTpmHandler = (val) => {
    setTpm(val ?? 1);
  };
  const submitHandler = (values) => {
    const payloaddetail = {
      group_name: values.name,
      description: values.description,
      tpm,
    };
    if (isEdit) payloaddetail["group_id"] = data.id;
    const payload = {
      resource: "group",
      method: isEdit ? "update" : "create",
      details: payloaddetail,
    };
    const request = isEdit ? updateGroup : createGroup;
    requestHelper(request, payload);
  };

  // HOOKS
  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(data);
      setTpm(data?.tpm);
    }
  }, [preview, data]);

  return (
    <Modal
      width={550}
      title={`${isEdit ? "Edit" : "Create"} Group`}
      className="create-group"
      open={preview}
      openHandler={closeHandler}>
      <Form form={form} onFinish={submitHandler} className="customize-form">
        <Form.Item
          name="name"
          rules={inputFieldRule({
            name: "Group name",
            isMax: true,
            max: 160,
          })}>
          <Input placeholder="Group name" />
        </Form.Item>
        <Form.Item
          name="description"
          rules={inputFieldRule({
            name: "Group description",
            isMax: true,
            max: 240,
          })}>
          <Input.TextArea placeholder="Group description" rows={4} />
        </Form.Item>
        <h4>Tokens Per Minute (thousands)</h4>
        <Row gutter={[20, 0]}>
          <Col span={19}>
            <Slider
              min={1}
              max={300}
              marks={marks}
              val={typeof tpm === "number" ? tpm : 1}
              onChange={setTpmHandler}
            />
          </Col>
          <Col span={5}>
            <div className="tmp-inputbox">
              <InputNumber
                value={tpm}
                min={1}
                max={300}
                maxLength={3}
                suffix="k"
                onChange={setTpmHandler}
              />
            </div>
          </Col>
        </Row>
        <div className="bottom-wrapper">
          <Button invertedTheme isLoading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateGroup;
