import React from "react";
// import { Checkbox } from "antd";
import {
  Button,
  //  Dropdown
} from "../../../../../../components";
import clsx from "clsx";

const ProjectFilesFilter = ({
  tags,
  searchText,
  searchHandler,
  selectedTags,
  selectTag,
  selectedFilters,
  selectFilter,
}) => {
  // STATES

  // CONST VALS
  const filters = [
    {
      key: "referenced",
      label: "In GPT Interactions",
    },
    // {
    //   key: "exposed",
    //   label: "Expose as app filter",
    // },
  ];

  // CUSTOM COMPONENT
  // const dropdownBox = (option) => {
  //   return (
  //     <>
  //       <Checkbox checked={selectedTags.includes(option)} />
  //       <span className="label">{option}</span>
  //     </>
  //   );
  // };

  return (
    <div className="filter-box customize-form">
      <input
        type="text"
        placeholder="Search..."
        className="search-input"
        value={searchText}
        onChange={({ target }) => searchHandler(target.value)}
      />
      {/* <Dropdown
        className="tags-filter"
        placeholder="Tags"
        emptyText="No tags found"
        options={tags}
        optionBox={dropdownBox}
        closeOnSelect={false}
        selectHandler={selectTag}
      /> */}
      {filters.map((filter, index) => (
        <Button
          key={index}
          className={clsx(
            "filter-btn",
            selectedFilters?.includes(filter.key) && "selected"
          )}
          onClick={() => selectFilter(filter.key)}
        >
          {filter.label}
        </Button>
      ))}
    </div>
  );
};

export default ProjectFilesFilter;
