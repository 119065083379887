import { createSlice } from "@reduxjs/toolkit";
import { manipulateAutoUpdateFieldsData } from "../../data-manipulator/project";

const ProjectReducer = createSlice({
  name: "projects",
  initialState: {
    messages: [],
    selectedProject: null,
    projects: [],
    projectMedia: [],
    projectTags: [],
    projectEditors: [],
    projectViewers: [],
    projectMediaStats: {},
    fieldsVisibility: {
      title: true,
      subtitle: true,
      input: true,
      desclaimer: true,
    },
    fieldsValues: {
      title: "",
      subtitle: "",
      input: "",
      desclaimer: "",
    },
  },
  reducers: {
    // PROJECTS
    getProjectsRequest() {},
    getProjectsSuccess(state, action) {
      state.projects = action.payload;
    },

    // CREATE PROJECT
    createProjectRequest() {},
    createProjectSuccess(state, { payload }) {
      state.projects = [...state.projects, payload];
    },

    // SELECT PROJECT
    getProjectDetailRequest(state) {
      state.selectedProject = null;
      state.projectMediaStats = {};
      state.messages = [];
      state.projectMedia = [];
      state.projectTags = [];
    },
    getProjectDetailSuccess(state, { payload }) {
      state.selectedProject = payload;
      state.fieldsValues = manipulateAutoUpdateFieldsData(payload);
    },

    // EDIT PROJECT
    editProjectRequest() {},
    editProjectSuccess(state, { payload }) {
      const data = { ...state.selectedProject, ...payload };
      state.selectedProject = data;
      state.fieldsValues = manipulateAutoUpdateFieldsData(data);
      const temp = [...state.projects];
      const index = temp.findIndex((item) => item.id === payload.id);
      temp[index] = data;
      state.projects = temp;
    },

    // DELETE PROJECT
    deleteProjectRequest() {},
    deleteProjectSuccess(state, { payload }) {
      const temp = [...state.projects];
      const projects = temp.filter((item) => item.id !== payload);
      state.projects = projects;
      state.messages = [];
    },

    // RESTORE PROJECT
    restoreProjectRequest() {},
    restoreProjectSuccess(state, { payload }) {
      state.projects = [...state.projects, payload];
    },

    // GET USERS
    getProjectUsersRequest() {},
    getProjectUsersSuccess(state, { payload }) {
      state.projectEditors = payload.editors;
      state.projectViewers = payload.viewers;
    },

    // ADD USERS
    addUserInProjectRequest() {},
    addUserInProjectSuccess(state, { payload }) {
      state.projectEditors = payload.editors;
      state.projectViewers = payload.viewers;
    },

    // REMOVE USERS
    removeUserFromProjectRequest() {},
    removeUserFromProjectSuccess(state, { payload }) {
      state.projectEditors = payload.editors;
      state.projectViewers = payload.viewers;
    },

    // GET MEDIA LISTING
    getProjectMediaRequest() {},
    getProjectMediaSuccess(state, { payload }) {
      const { media, globaltags } = payload;
      state.projectMedia = media;
      state.projectTags = globaltags;
    },

    // DELETE MEDIA
    deleteMediaRequest() {},

    // UPDATE MEDIA
    updateMediaRequest() {},
    updateMediaSuccess(state, { payload }) {
      const { media, globaltags } = payload;
      state.projectMedia = media;
      state.projectTags = globaltags;
    },

    // GET MEDIA STATS
    getProjectMediaStatsRequest() {},
    getProjectMediaStatsSuccess(state, { payload }) {
      state.projectMediaStats = payload;
    },

    // MESSAGES
    getMessagesRequest() {},
    sendMessageRequest(state, action) {
      const message = {
        message: action.payload,
      };
      const tempdate = [...state.messages, message];
      state.messages = tempdate;
    },
    recieveMessageRequest(state, action) {
      state.messages = [...state.messages, action.payload];
    },

    // FIELDS VISIBILITY
    setFieldsVisibility(state, action) {
      state.fieldsVisibility = {
        ...state.fieldsVisibility,
        [action.payload]: !state.fieldsVisibility[action.payload],
      };
    },
    // FIELDS VALUES
    setFieldsValues(state, action) {
      state.fieldsValues = {
        ...state.fieldsValues,
        [action.payload.field]: action.payload.value,
      };
    },
  },
});

export const {
  getProjectsRequest,
  getProjectsSuccess,
  getProjectDetailRequest,
  getProjectDetailSuccess,
  createProjectRequest,
  createProjectSuccess,
  editProjectRequest,
  editProjectSuccess,
  deleteProjectRequest,
  deleteProjectSuccess,
  restoreProjectRequest,
  restoreProjectSuccess,
  getProjectUsersRequest,
  getProjectUsersSuccess,
  addUserInProjectRequest,
  addUserInProjectSuccess,
  removeUserFromProjectRequest,
  removeUserFromProjectSuccess,
  getProjectMediaRequest,
  getProjectMediaSuccess,
  deleteMediaRequest,
  updateMediaRequest,
  updateMediaSuccess,
  getProjectMediaStatsRequest,
  getProjectMediaStatsSuccess,
  getMessagesRequest,
  sendMessageRequest,
  recieveMessageRequest,
  setFieldsVisibility,
  setFieldsValues,
} = ProjectReducer.actions;

export default ProjectReducer.reducer;
