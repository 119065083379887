import React, { useState } from "react";
import { Header, Sidebar } from "./components";
import clsx from "clsx";
import { Backdrop, CreateAdvanceProject } from "../../components";
import { DASHOBARD_SIDEBAR_PLACEMENT_RIGHT } from "../../constants";
import "./styles.scss";

const DashboardSharedLayout = ({ children }) => {
  // STATES
  const [preview, setpreview] = useState({
    sidebar: true,
    createProject: false,
  });

  // HANDLERS
  const previewHandler = (key) => {
    setpreview({
      ...preview,
      [key]: !preview[key],
    });
  };

  return (
    <div className="dashboard-wrapper">
      <Header
        sidebarPreview={preview.sidebar}
        setSidebarPreview={() => previewHandler("sidebar")}
        createProjectHandler={() => previewHandler("createProject")}
      />
      <main
        role="main"
        className={clsx(
          "layout-wrapper",
          !preview.sidebar && "active",
          DASHOBARD_SIDEBAR_PLACEMENT_RIGHT && "rtl"
        )}
      >
        {!preview.sidebar && (
          <Backdrop isMobile onClick={() => previewHandler("sidebar")} />
        )}
        <Sidebar
          preview={preview.sidebar}
          previewHandler={() => previewHandler("sidebar")}
          createProjectHandler={() => previewHandler("createProject")}
        />
        <div className="content">{children}</div>
      </main>
      {/* <Introduction /> */}
      <CreateAdvanceProject
        preview={preview.createProject}
        previewHandler={() => previewHandler("createProject")}
      />
    </div>
  );
};

export default DashboardSharedLayout;
