import React from "react";

const ApiDoc = () => {
  return (
    <iframe
      title="Api Doc"
      style={{
        height: "100vh",
        width: "100%",
      }}
      src={"/api.html"}
    />
  );
};

export default ApiDoc;
