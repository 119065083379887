import React, { useState, useMemo } from "react";
import { Modal, ProjectConfirmationModal } from "../../";
import {
  // OnboardingStep,
  ProjectStep,
  ModelStep,
  // DatabaseStep,
  // DataManagementStep,
  // PublicInterfaceStep,
  // ApiGenerationStep,
  CreateProjectStep,
  CongratulationStep,
} from "./components";
import "./styles.scss";

const CreateAdvanceProject = ({ preview, previewHandler }) => {
  // STATES
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [projectConfirmationData, setprojectConfirmationData] = useState({});
  const [projectConfirmationPreview, setprojectConfirmationPreview] =
    useState(false);

  // CONST VALS
  const STEP_TITLES = [
    // "User Onboarding",
    "Create a project",
    "Model Selection",
    // "Database Selection",
    // "Data Management",
    // "Public Interface",
    // "API Generation",
  ];

  // HANDLERS
  const closeHandler = () => {
    previewHandler();
    setSelectedData({});
    setCurrentStep(1);
  };
  const previosStepHandler = () => {
    setCurrentStep(currentStep - 1);
  };
  const nextStepHandler = (data = {}) => {
    setSelectedData((prevData) => ({ ...prevData, ...data }));
    setCurrentStep(currentStep + 1);
  };
  const createProjectErrorHandler = (data) => {
    if (!data?.project_id) return;
    previewHandler();
    setprojectConfirmationPreview(true);
    setprojectConfirmationData(data);
  };
  const projectConfirmationPreviewHandler = () => {
    setprojectConfirmationPreview(false);
    previewHandler();
  };
  const projectSuccessHandler = (data) => {
    projectConfirmationPreviewHandler();
    setSelectedData((prevData) => ({ ...prevData, ...data }));
    setCurrentStep(currentStep + 1);
  };

  // HOOKS
  const StepBox = useMemo(() => {
    switch (currentStep) {
      // case 2:
      //   return (
      //     <ProjectStep
      //       data={selectedData}
      //       previousStepHander={previosStepHandler}
      //       nextStepHandler={nextStepHandler}
      //     />
      //   );
      case 2:
        return (
          <ModelStep
            data={selectedData}
            previousStepHander={previosStepHandler}
            nextStepHandler={nextStepHandler}
          />
        );
      // case 3:
      //   return (
      //     <DatabaseStep
      //       data={selectedData}
      //       previousStepHander={previosStepHandler}
      //       nextStepHandler={nextStepHandler}
      //     />
      //   );
      // case 5:
      //   return (
      //     <DataManagementStep
      //       data={selectedData}
      //       previousStepHander={previosStepHandler}
      //       nextStepHandler={nextStepHandler}
      //     />
      //   );
      // case 5:
      //   return (
      //     <PublicInterfaceStep
      //       data={selectedData}
      //       previousStepHander={previosStepHandler}
      //       nextStepHandler={nextStepHandler}
      //     />
      //   );
      // case 6:
      //   return (
      //     <ApiGenerationStep
      //       data={selectedData}
      //       previousStepHander={previosStepHandler}
      //       nextStepHandler={nextStepHandler}
      //     />
      //   );
      case 3:
        return (
          <CreateProjectStep
            data={selectedData}
            previousStepHander={previosStepHandler}
            nextStepHandler={nextStepHandler}
            errorHandler={createProjectErrorHandler}
          />
        );
      case 4:
        return (
          <CongratulationStep
            data={selectedData}
            previewHandler={closeHandler}
          />
        );

      default:
        return (
          <ProjectStep
            data={selectedData}
            previousStepHander={previosStepHandler}
            nextStepHandler={nextStepHandler}
          />
        );
      // return <OnboardingStep nextStepHandler={nextStepHandler} />;
    }
  }, [currentStep]);

  return (
    <>
      <Modal
        open={preview}
        width={840}
        openHandler={closeHandler}
        className="create-advance-project-wrapper">
        {/* {currentStep < 8 && ( */}
        {currentStep < 3 && (
          <div className="title-wrapper">
            <h2>{STEP_TITLES[currentStep - 1]}</h2>
            <span className="current-step">
              Step {currentStep} of {STEP_TITLES.length}
            </span>
          </div>
        )}
        <div className="step-box">{StepBox}</div>
      </Modal>
      <ProjectConfirmationModal
        preview={projectConfirmationPreview}
        data={selectedData}
        createResponse={projectConfirmationData}
        previewHandler={projectConfirmationPreviewHandler}
        successHandler={projectSuccessHandler}
      />
    </>
  );
};

export default CreateAdvanceProject;
