import { createSlice } from "@reduxjs/toolkit";

const UserReducer = createSlice({
  name: "user",
  initialState: {
    data: {},
    onboardingDetails: [],
    groupAccess: false,
  },
  reducers: {
    // GET USER DETAILS
    getUserDetailsRequest() {},
    getUserDetailsSuccess(state, { payload }) {
      state.data = payload.basicDetails;
      state.onboardingDetails = payload.onboardingDetails;
    },
    getUserAccessRequest() {},
    getUserAccessSuccess(state, { payload }) {
      state.groupAccess = payload.group_admin;
    },
  },
});

export const {
  getUserDetailsRequest,
  getUserDetailsSuccess,
  getUserAccessRequest,
  getUserAccessSuccess,
} = UserReducer.actions;

export default UserReducer.reducer;
