import axios from "axios";
import {
  ERROR_API_NOT_FOUND,
  ERROR_ACCOUNT_BLOCKED,
  ERROR_SOMETHING_WENT_WRONG,
  ERROR_NETWORK_NOT_AVAILABLE,
  ERROR_TOKEN_EXPIRE,
} from "../config/web-service";
import { API_LOG, API_TIMEOUT } from "../constants";

const manipulateResponse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.data && !response.data.error) {
      resolve({ status: true, data: response.data });
    } else {
      reject(response.data || ERROR_SOMETHING_WENT_WRONG);
    }
  });
};
const ApiHandler = async (request, url, data, headers, baseUrl) => {
  if (API_LOG) {
    console.log("url", baseUrl + url);
    console.log("data", data);
    console.log("headers", headers);
  }
  try {
    const response = await axios({
      baseURL: baseUrl,
      timeout: API_TIMEOUT,
      method: request,
      url: url,
      data: data,
      headers: headers,
    });
    if (API_LOG) {
      console.log("response", response);
    }
    return manipulateResponse(response);
  } catch ({ response }) {
    if (API_LOG) {
      console.log("response", response);
    }
    if (response?.problem === "NETWORK_ERROR") {
      return { status: false, message: ERROR_NETWORK_NOT_AVAILABLE };
    }
    switch (response?.status) {
      case 400:
        return {
          status: false,
          message: response?.data?.error ?? ERROR_SOMETHING_WENT_WRONG,
          data: response?.data,
        };
      case 404:
        return {
          status: false,
          message: ERROR_API_NOT_FOUND,
          data: response?.data,
        };
      case 403:
        return {
          status: false,
          message: ERROR_ACCOUNT_BLOCKED,
          data: response?.data,
        };
      case 401:
        return {
          status: false,
          message: ERROR_TOKEN_EXPIRE,
          data: response?.data,
        };
      case 500:
        return {
          status: false,
          message: ERROR_SOMETHING_WENT_WRONG,
          data: response?.data,
        };
      default:
        return {
          status: false,
          message:
            typeof response?.data?.message === "object"
              ? response?.data?.message[0]
              : response?.data?.message ?? ERROR_SOMETHING_WENT_WRONG,
          data: response?.data,
        };
    }
  }
};

export default ApiHandler;
