import ErrorThumbnail from "../assets/images/error-thumb.svg";
import Logo from "../assets/images/logo.png";
import LogoThumb from "../assets/images/logo-thumb.png";
import AiLoader from "../assets/images/ai-loader.gif";
import UserThumb from "../assets/images/user.jpeg";
import ASUBanner from "../assets/images/asu-banner.png";

const Images = {
  ErrorThumbnail,
  Logo,
  LogoThumb,
  AiLoader,
  UserThumb,
  ASUBanner,
};

export default Images;
