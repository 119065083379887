import { useState } from "react";
import { useDispatch } from "react-redux";

export const CustomDispatch = (request, loading = false) => {
  const [isLoading, setLoading] = useState(loading);
  const dispatch = useDispatch();

  const sendRequest = (args = {}) => {
    const {
      payload,
      queryParams,
      pathParams,
      success,
      error,
      callback,
      ...rest
    } = args;
    setLoading(true);
    dispatch(
      request({
        payload,
        queryParams,
        pathParams,
        ...rest,
        responseCallback(isApiSucceed, response) {
          callback && callback(isApiSucceed, response);
          setLoading(false);
          if (isApiSucceed === false) {
            error && error(response);
            return;
          }
          success && success(response);
        },
      })
    );
  };

  return [sendRequest, isLoading];
};
