import { combineReducers } from "@reduxjs/toolkit";

import general from "./general";
import user from "./user";
import project from "./project";
import groups from "./groups";
import chat from "./chat";

export default combineReducers({
  general,
  user,
  project,
  groups,
  chat,
});
