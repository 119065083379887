import React from "react";
import { Button, Modal } from "../";
import "./styles.scss";

const ConfirmationModal = ({
  title = "",
  className = "",
  preview = false,
  description = "",
  isLoading = false,
  confirmBtnText = "Confirm",
  previewHandler = () => {},
  confirmBtnHandler = () => {},
}) => {
  return (
    <Modal
      openHandler={previewHandler}
      open={preview}
      title={title}
      className={`confirmation-modal ${className}`}>
      <p className="description">{description}</p>
      <div className="bottom-btn">
        <Button
          invertedTheme
          bordered
          className="cancel-btn"
          onClick={previewHandler}>
          Cancel
        </Button>
        <Button
          invertedTheme
          loaderColor="#000"
          className="confirm-btn"
          isLoading={isLoading}
          onClick={confirmBtnHandler}>
          {confirmBtnText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
