import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { PROJECT_TEMPLATE_ICONS } from "../../../../constants";

function CreateCard({ card, onClick }) {
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
      className={clsx("card", "create-card")}
    >
      <FontAwesomeIcon icon={faStar} className="like" />
      <div className="data-wrapper">
        <div className="icon-container">
          <img src={PROJECT_TEMPLATE_ICONS[card.type]} alt="create icon" />
        </div>
        <div className="text-container">
          <h1 className="title">{card.title}</h1>
          <p className="description description-small">{card.desc}</p>
        </div>
      </div>
    </div>
  );
}

export default CreateCard;
