import React from "react";
import { Modal } from "../../";
import { USE_CASES_OPTIONS } from "../../../constants";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "antd";

const CreateTemplateProject = ({ preview, previewHandler }) => {
  const useCases = USE_CASES_OPTIONS.filter((item) => item.label !== "Other");

  // HANDLERS
  const closeHandler = () => {
    previewHandler();
  };

  return (
    <>
      <Modal
        open={preview}
        width={600}
        openHandler={closeHandler}
        className="create-template-project-wrapper"
      >
        <div className="title-wrapper">
          <h2>Starting Templates</h2>
          <p>
            Let's set up your project! Start with pre-configured options, or
            select the advanced setup option. All options, except for the
            database, can be customization after your project has been created.
          </p>
        </div>
        <Row className="use-cases" gutter={[16, 16]}>
          {useCases.map((item, index) => (
            <Col xs={12} key={index}>
              <div className="use-case">
                <span className="thumb">
                  <FontAwesomeIcon icon={faStarOfLife} />
                </span>
                <h2>{item.label}</h2>
                <p>Sit quia dolorem et quae earum non dolorem.</p>
              </div>
            </Col>
          ))}
          <Col xs={12}>
            <div className="use-case">
              <span className="thumb">
                <FontAwesomeIcon icon={faStarOfLife} />
              </span>
              <h2>Advanced setup</h2>
              <p>Sit quia dolorem et quae earum non dolorem.</p>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CreateTemplateProject;
