const manipulateUserOnboardingDetail = (data) => {
  const detail = [];
  Object.keys(data).forEach(function (key) {
    switch (key) {
      case "display_name":
        detail.push({
          sort: 1,
          title: "Name",
          value: data[key].raw,
        });
        break;
      case "email_address":
        detail.push({
          sort: 2,
          title: "Email",
          value: data[key].raw,
        });
        break;
      case "phone":
        detail.push({
          sort: 3,
          title: "Phone",
          value: data[key].raw,
        });
        break;
      case "working_title":
        detail.push({
          sort: 4,
          title: "Title",
          value: data[key].raw,
        });
        break;
      case "primary_search_department_affiliation":
        detail.push({
          sort: 5,
          title: "Department",
          value: data[key].raw,
        });
        break;
      default:
        break;
    }
  });

  detail.sort((a, b) => a.sort - b.sort);

  return detail;
};

const manipulateUserBasicDetail = (data) => {
  const user = {
    profilePic: data?.photo_url?.raw,
    username: data?.display_name?.raw ?? "",
    email: data?.email_address?.raw ?? "",
    phone: data?.phone?.raw ?? "",
  };
  return user;
};

export const manipulateUserData = ({ results }) => {
  const data = results?.[0] ?? {};
  const basicDetails = manipulateUserBasicDetail(data);
  const onboardingDetails = manipulateUserOnboardingDetail(data);
  return { basicDetails, onboardingDetails };
};
